import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export type ModalContextType = {
  isSendDocumentModalOpen: boolean;
  setSendDocumentModalOpen: (state: boolean) => void;
  showLimitReachedModal: boolean;
  setShowLimitReachedModal: (state: boolean) => void;
  isForgotPasswordModalOpen: boolean;
  forgotPasswordEmail?: string;
  setForgotPasswordModalOpen: (state: boolean, email?: string) => void;
  showSendMailModal: boolean;
  setShowSendMailModal: (state: boolean) => void;
  showLockedFeatureUpgradeModal: boolean;
  setShowLockedFeatureUpgradeModal: (state: boolean) => void;
  showCreateDynamicConsultancyModal: boolean;
  setShowCreateDynamicConsultancyModal: (state: boolean) => void;
  showQuoteInfoModal: boolean;
  setShowQuoteInfoModal: (state: boolean) => void;
  showConcludeModal: boolean;
  setShowConcludeModal: (state: boolean) => void;
  showQuoteCreationModal: boolean;
  setShowQuoteCreationModal: (state: boolean) => void;
  consultancyQuotePrefillData?: {
    message: string;
    amount: number;
    nonTaxableAmount: number;
  };
  setConsultancyQuotePrefillData: (consultancyQuotePrefillData: {
    message: string;
    amount: number;
    nonTaxableAmount: number;
  }) => void;
  showNotaryHandoffModal: boolean;
  setShowNotaryHandoffModal: (state: boolean) => void;
  showAttachLexdoitDocumentModal: boolean;
  setShowAttachLexdoitDocumentModal: (state: boolean) => void;

  showRegistrationModal: boolean;
  setShowRegistrationModal: (state: boolean) => void;
  isLivestormModalOpen: boolean;
  setIsLivestormModalOpen: (value: boolean) => void;
  livestormQuizKind: string;
  setLivestormQuizKind: (value: string) => void;
  reOpenConsultancyModal: boolean;
  setReOpenConsultancyModal: (state: boolean) => void;
  showAccountingSubConfirmModal: boolean;
  setShowAccountingSubConfirmModal: (state: boolean) => void;
  hasAlreadyActivityInProgress: boolean;
  setAlreadyActivityInProgress: (value: boolean) => void;
  hasActivityConcluded: boolean;
  setHasActivityConcluded: (value: boolean) => void;
  showAccountingRenewPastDueModal: boolean;
  setShowAccountingRenewPastDueModal: (state: boolean) => void;
  showNotaryStartupDocumentsModal: boolean;
  setShowNotaryStartupDocumentsModal: (state: boolean) => void;
  consultancyStandbyModalOpen: boolean;
  setConsultancyStandbyModalOpen: (state: boolean) => void;
};

export const ModalContext = React.createContext<Partial<ModalContextType>>({
  isSendDocumentModalOpen: false,
});

type ModalContextProps = {};
export function ModalContextProvider(
  props: React.PropsWithChildren<ModalContextProps>
) {
  const router = useRouter();

  const [isSendDocumentModalOpen, setSendDocumentModalOpen] = useState(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);
  const [isForgotPasswordModalOpen, _setForgotPasswordModalOpen] =
    useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(undefined);
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [showLockedFeatureUpgradeModal, setShowLockedFeatureUpgradeModal] =
    useState(false);
  const [
    showCreateDynamicConsultancyModal,
    setShowCreateDynamicConsultancyModal,
  ] = useState(false);
  const [showQuoteInfoModal, setShowQuoteInfoModal] = useState(false);
  const [showConcludeModal, setShowConcludeModal] = useState(false);
  const [showQuoteCreationModal, setShowQuoteCreationModal] = useState(false);
  const [consultancyQuotePrefillData, setConsultancyQuotePrefillData] =
    useState(undefined);
  const [showNotaryHandoffModal, setShowNotaryHandoffModal] = useState(false);
  const [showAttachLexdoitDocumentModal, setShowAttachLexdoitDocumentModal] =
    useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [isLivestormModalOpen, setIsLivestormModalOpen] = useState(false);
  const [livestormQuizKind, setLivestormQuizKind] = useState('');
  const [reOpenConsultancyModal, setReOpenConsultancyModal] = useState(false);
  const [showAccountingSubConfirmModal, setShowAccountingSubConfirmModal] =
    useState(false);
  const [hasAlreadyActivityInProgress, setAlreadyActivityInProgress] =
    useState(false);
  const [hasActivityConcluded, setHasActivityConcluded] = useState(false);
  const [showAccountingRenewPastDueModal, setShowAccountingRenewPastDueModal] =
    useState(false);
  const [showNotaryStartupDocumentsModal, setShowNotaryStartupDocumentsModal] =
    useState(false);
  const [consultancyStandbyModalOpen, setConsultancyStandbyModalOpen] =
    useState(false);

  function resetModalState() {
    setSendDocumentModalOpen(false);
    setShowLimitReachedModal(false);
    _setForgotPasswordModalOpen(false);
    setForgotPasswordEmail(undefined);
    setShowSendMailModal(false);
    setShowLockedFeatureUpgradeModal(false);
    setShowCreateDynamicConsultancyModal(false);
    setShowQuoteInfoModal(false);
    setShowConcludeModal(false);
    setShowQuoteCreationModal(false);
    setShowNotaryHandoffModal(false);
    setShowAttachLexdoitDocumentModal(false);
    setShowRegistrationModal(false);
    setIsLivestormModalOpen(false);
    setLivestormQuizKind('');
    setReOpenConsultancyModal(false);
    setShowAccountingSubConfirmModal(false);
    setAlreadyActivityInProgress(false);
    setHasActivityConcluded(false);
    setShowAccountingRenewPastDueModal(false);
    setShowNotaryStartupDocumentsModal(false);
    setConsultancyStandbyModalOpen(false);
  }

  function setForgotPasswordModalOpen(state, email) {
    _setForgotPasswordModalOpen(state);
    setForgotPasswordEmail(email);
  }

  useEffect(() => {
    if (process.browser) {
      router.events.on('routeChangeComplete', resetModalState);
    }

    return () => {
      router.events.off('routeChangeComplete', resetModalState);
    };
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isSendDocumentModalOpen,
        setSendDocumentModalOpen,
        showLimitReachedModal,
        setShowLimitReachedModal,
        isForgotPasswordModalOpen,
        forgotPasswordEmail,
        setForgotPasswordModalOpen,
        showSendMailModal,
        setShowSendMailModal,
        showLockedFeatureUpgradeModal,
        setShowLockedFeatureUpgradeModal,
        showCreateDynamicConsultancyModal,
        setShowCreateDynamicConsultancyModal,
        showQuoteInfoModal,
        setShowQuoteInfoModal,
        showConcludeModal,
        setShowConcludeModal,
        showQuoteCreationModal,
        setShowQuoteCreationModal,
        consultancyQuotePrefillData,
        setConsultancyQuotePrefillData,
        showNotaryHandoffModal,
        setShowNotaryHandoffModal,
        showAttachLexdoitDocumentModal,
        setShowAttachLexdoitDocumentModal,
        showRegistrationModal,
        setShowRegistrationModal,
        isLivestormModalOpen,
        setIsLivestormModalOpen,
        livestormQuizKind,
        setLivestormQuizKind,
        reOpenConsultancyModal,
        setReOpenConsultancyModal,
        showAccountingSubConfirmModal,
        setShowAccountingSubConfirmModal,
        hasAlreadyActivityInProgress,
        setAlreadyActivityInProgress,
        hasActivityConcluded,
        setHasActivityConcluded,
        showAccountingRenewPastDueModal,
        setShowAccountingRenewPastDueModal,
        showNotaryStartupDocumentsModal,
        setShowNotaryStartupDocumentsModal,
        consultancyStandbyModalOpen,
        setConsultancyStandbyModalOpen,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}
