import { useAxios } from 'context/AxiosContext';
import { useMutation, useQueryClient } from 'react-query';
import { setCookie } from 'react-use-cookie';

export function useDoLogout() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      try {
        await axios.post('/auth/logout', {});
      } catch (err) {
        throw new Error(err);
      }
      setCookie('AUTH_TOKEN', null);
    },
    {
      onSettled: () => {
        queryClient.resetQueries();
      },
    }
  );
}
