import { Button, Spacer, Text } from 'ds';
import React from 'react';
import FlexView from 'react-flexview/lib';
import Link from 'next/link';
import { IoArrowForwardSharp, IoChatbubblesOutline } from 'react-icons/io5';
import {
  LinksColumn,
  LinkWrapper,
  NavigationAttivita,
  RightSide,
  LeftSide,
  Separator,
  NavigationFooter,
} from './shared';

export default function NavigationSubMenuServices() {
  return (
    <NavigationAttivita grow>
      <FlexView grow className="content-wrapper">
        <RightSide shrink={false} width="70%" column>
          <Text fontWeight="900" variant="body">
            Apri la tua attività con il nostro supporto legale e contabile
            completo
          </Text>
          <Text color="#838383" fontSize="2" variant="body">
            Potrai concentrarti sul tuo business con tranquillità e senza
            preoccupazioni. Pensiamo a tutto noi!
          </Text>
          <Spacer variant="small" />
          <LinkWrapper>
            <LinksColumn column>
              <a href="/costituzione-societa-srl-srls/ ">
                <Text fontSize="2" color="#2E3132" variant="body">
                  Apertura Società
                </Text>
              </a>
            </LinksColumn>
            <LinksColumn column>
              <a href="/aprire-partita-iva-online/">
                <Text fontSize="2" color="#2E3132" variant="body">
                  Aprire Partita IVA
                </Text>
              </a>
            </LinksColumn>
            <LinksColumn column>
              <a href="/costituire-associazione-online/">
                <Text fontSize="2" color="#2E3132" variant="body">
                  Costituzione Associazione
                </Text>
              </a>
            </LinksColumn>
          </LinkWrapper>
          <Spacer variant="small" />
          <Separator />
          <Spacer variant="small" />
          <NavigationFooter />
        </RightSide>

        <LeftSide column width="30%">
          <IoChatbubblesOutline fontSize="35" color="#333333" />
          <Spacer variant="small" />
          <Text fontWeight="700" color="#333333" variant="body">
            Non sai quale attività è giusta per te?
          </Text>
          <Text fontSize="2" color="#333333" variant="body">
            Fai il quiz gratis e scopri quale attività ti conviene aprire. Ti
            guidiamo noi.
          </Text>

          <Spacer variant="small" />
          <a href="/aprire-nuova-attivita/">
            <Button
              icon={<IoArrowForwardSharp />}
              iconOnRight
              size="medium"
              className="btn"
              fontSize="1"
            >
              Aprire un'attività
            </Button>
          </a>
        </LeftSide>
      </FlexView>
    </NavigationAttivita>
  );
}
