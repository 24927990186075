import { useMemo, createContext, useContext } from 'react';
import Axios, { AxiosInstance } from 'axios';
var cookie = require('cookie-cutter');
import AppConstants from '../utils/AppConstants';
import { LOGIN, SIGN_UP } from 'constants/routes';
import { setCookie } from 'react-use-cookie';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';

export const AxiosContext = createContext<AxiosInstance>(Axios);

export default function AxiosProvider({
  children,
  adminAuthentication = false
}: React.PropsWithChildren<{ adminAuthentication?: boolean }>) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const cookieName = adminAuthentication ? 'Lex_WT' : 'AUTH_TOKEN'

  const axios = useMemo(() => {
    const axios = Axios.create({
      baseURL: AppConstants.API_ENDPOINT,
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store',
      },
    });

    axios.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = cookie.get(cookieName);

      if (token) {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers.authorization = `Token token=${token}`;
      }

      return config;
    });

    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        const token = cookie.get(cookieName);
        if (
          err?.response?.status === 401 &&
          typeof token === 'string' &&
          token !== 'null'
        ) {
          if (!window.location.pathname.includes('/app/verify/')) {
            setCookie(cookieName, null);
          }

          if (
            window.location.pathname !== `/app${SIGN_UP}/` &&
            window.location.pathname !== `/app${LOGIN}/` &&
            !window.location.pathname.includes('/app/verify/')
          ) {
            queryClient.resetQueries();
            router.push(SIGN_UP);
          }
        }
        return Promise.reject(err);
      }
    );

    return axios;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return useContext(AxiosContext);
}
