const variables = {
  white: '#ffffff',
  black: '#000000',
  algae: '#4ab062',
  azure: '#1a91eb',
  brightOrange: '#ff5c00',
  darkValencia: '#8d231b',
  cardinal: '#b62025',
  'dark-mint': '#57be6f',
  dusckyBlue: '#486b95',
  darkGreyBlue: '#335a65',
  valencia: '#d94135',
  lightValencia: '#dd554a',
  roman: '#e05552',
  darkYellow: '#ffdf00',
  wildSand: '#f4f4f4',
  border: '#d9d9d9',
  borderHover: '#a7acac',
  alto: '#d3d3d3',
  daintree: '#002449',
  bigStone: '#14343d',
  mercury: '#eaeaea',
  osloGray: '#7d8e93',
  greyish: '#b0afaf',
  silver: '#c8c5c5',
  lightSilver: '#d8d8d8',
  grayChateau: '#9ba3a7',
  greyText: '#999',
  semanticGrey: '#f4f4f4',
  verifyEmailSubHeaderBackground: '#4AB062',
  fontLato: "'Lato', sans-serif",
  light: '300',
  regular: '400',
  semibold: '600',
  bold: '700',
  pageContentWidth: '1200px',
  tabletWidth: 768,
  desktopWidth: 900,
  headerHeight: '72px',
  black015: 'rgba(0, 0, 0, 0.15)',
  emailNotValidatedSubHeaderHeight: '58px',
  emailNotValidatedSubHeaderHeightMobile: '98px',
  secondaryBlue: '#002449',
  cardsGrayBg: '#f5f5f5',
  iconsGrayBg: '#DDDADA',
  iconsDarkGray: '#646464',
  iconsLightGray: '#9B9B9B',
  iconsDarkBlue: '#009FF4',
  iconsLightBlue: '#1AAEF5',
  deepOrange: '#E96D2F',
  lightOrange: '#FFC7AB',
  lightGrey: '#F3F3F3',
  mustardYellow: '#E1A800',
  lightMustardYellow: '#EDCC83',
  palatinateBlue: '#3D4AD6',
  lightPalatinateBlue: '#9EA4D7',
  darkAlgae: '#419154',
  borderDarkGrey: '#B1B1B1',
  textDarkGrey: '#828282',
  textLightGrey: '#7E7E7E',
  buttonDarkBlue: '#18356B',
  mobileMenuBackgroundGrey: '#FBFBFB',
  userUpperBannerColor: '#ffe07a',
  userNABannerColor: '#E7F2E2',
  userNABannerColorIcon: '#F2F8F0',
  userTextBannerColor: '#2E3132',
  userArchiveBannerColor: '#FBEDE1',
  userArchiveBannerColorIcon: '#FDF5ED',
  userConsultanciesBannerColor: '#FAF4DC',
  userConsultanciesBannerColorIcon: '#FCF9ED',
  userAccountantsBannerColor: '#E7E8F9',
  userAccountantsBannerColorIcon: '#F3F4FD',
};
export default variables;
