import React from 'react';
import cx from 'classnames';
import { Button } from 'ds';
import useUser from 'queries/useUser';
import { useRouter } from 'next/router';
import { QUESTION } from 'constants/routes';
import useDocumentKind from 'queries/useDocumentKind';
import { FaSave } from 'react-icons/fa';

interface IDocumentSaveForLaterProps {
  className?: string;
  fluid?: boolean;
  onSaveClick: () => void;
}

export default function DocumentSaveForLater(
  props: IDocumentSaveForLaterProps
) {
  const router = useRouter();
  const { documentKind: documentKindUrl } = router.query;

  const { data: user } = useUser();
  const { data: optionalDocumentKind } = useDocumentKind(
    documentKindUrl as string
  );

  const { onSaveClick, className, fluid } = props;

  if ((!optionalDocumentKind && router.pathname === QUESTION) || user) {
    return null;
  }

  return (
    <Button
      fluid={fluid}
      className={cx('document-save-for-later', className)}
      icon={<FaSave />}
      onClick={onSaveClick}
      style={{ fontSize: 16 }}
    >
      Salva per continuare più tardi
    </Button>
  );
}
