import { APIDocumentKind } from 'lexdoit-models';
import { useQuery } from 'react-query';
import { getDocumentKindIdByHyphens } from 'utils/HyphensUrlUtil';
import { useAxios } from '../context/AxiosContext';

export default function useDocumentKind(documentKindHyphensUrl: string) {
  // Get our Axios instance from our previously created Hook
  const axios = useAxios();

  const id = getDocumentKindIdByHyphens(documentKindHyphensUrl);

  return useQuery<APIDocumentKind>(
    ['documentKindController_findByIncrementalId', documentKindHyphensUrl],
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get<APIDocumentKind>(
        '/documentKinds/findByDocKindId',
        {
          params: {
            id,
          },
        }
      );

      // Return the data from the Axios response
      return data;
    },
    {
      enabled: !!id,
    }
  );
}
