import ReactGA from 'react-ga';
import AppConstants from './AppConstants';

const GAAnalytics = {
  initialize: (cookieConsent: boolean) => {
    const additionalGaOptions: any = {
      allowAdFeatures: cookieConsent,
    };
    ReactGA.initialize(AppConstants.GATrackingNumber, {
      gaOptions: { siteSpeedSampleRate: 100, ...additionalGaOptions },
    });
    ReactGA.plugin.require('ecommerce');
    ReactGA.set({ anonymizeIp: true });
    ReactGA.set({ hostname: window.location.hostname });
  },
  // pass category, action, ?label, ?value as arguments
  track: (category: string, action: string, label?: string, value?: number) =>
    ReactGA.event({
      category,
      action,
      label,
      value,
    }),
  trackPage: (pathname) => ReactGA.pageview(pathname),
  trackVirtualPageView: () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  },
  onCookieConsent: () => {
    ReactGA.set({ allowAdFeatures: true });
  },
  trackUserSubscribed: ({ subscriptionTier }) => {
    ReactGA.event({
      category: 'user',
      action: 'subscribed',
      label: 'user-subscribed',
      value: subscriptionTier,
    });
  },
  trackAbTestPageView: ({
    experimentId,
    variantId,
  }: {
    experimentId: string;
    variantId: string;
  }) => {
    ReactGA.set({ exp: `${experimentId}.${variantId}` });
    ReactGA.pageview(window.location.pathname);
  },
  setLeadIdDimension: (userId: number) => {
    if (!userId) {
      return;
    }
    ReactGA.set({
      dimension2: userId.toString(),
    });
  },
  trackQuizStarted: () => {
    ReactGA.event({
      category: 'quiz-nuove-attivita',
      action: 'quiz-started',
      label: 'quiz-started',
    });
  },
  trackStatutoQuiz: () => {
    ReactGA.event({
      category: 'quiz-nuove-attivita',
      action: 'quiz-started',
      label: 'quiz-started-societa',
    });
  },
  trackQuizCompleted: (result: string) => {
    ReactGA.event({
      category: 'quiz-nuove-attivita',
      action: 'quiz-completed',
      label: `quiz-completed-${result}`,
    });
  },
  activateOptimize: () => {
    ReactGA.event({
      category: 'optimize.activate',
      action: 'optimize.activate',
      label: `optimize.activate`,
      nonInteraction: true,
    });
  },
  trackSubscriptionWithPayContent: ({
    id,
    price,
    subscriptionTier = '',
    payContent,
  }) => {
    const eventPrefix = (() => {
      switch (payContent) {
        case 'piva': {
          return 'Partita-Iva';
        }
        case 'attivita': {
          return 'Attivita';
        }

        case 'srl': {
          return 'Srl';
        }
        case 'associazione': {
          return 'Associazione';
        }

        case 'startup': {
          return 'Startup';
        }

        case 'srls': {
          return 'Srls';
        }

        default: {
          return '';
        }
      }
    })();

    const titleCaseSubscriptionTier =
      subscriptionTier.charAt(0).toUpperCase() + subscriptionTier.substring(1);

    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id,
      revenue: price,
    });
    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id,
      name: `${eventPrefix}-${titleCaseSubscriptionTier}`,
      category: `${eventPrefix}`,
      price,
      quantity: '1',
    });
    ReactGA.plugin.execute('ecommerce', 'send', undefined);
  },

  trackSubscriptionPurchase: ({ id, subscriptionTier, price }) => {
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id,
      revenue: price,
    });
    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id,
      name: subscriptionTier,
      category: 'Subscription',
      price,
      quantity: '1',
    });
    ReactGA.plugin.execute('ecommerce', 'send', undefined);
  },
  trackDocumentPurchase: ({ id, name, price }) => {
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id,
      revenue: price,
    });
    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id,
      name, // DocKindID
      category: 'Single Document',
      price,
      quantity: '1',
    });
    ReactGA.plugin.execute('ecommerce', 'send', undefined);
  },
  trackPaymentShown: (paymentType) =>
    ReactGA.event({
      category: 'payment',
      action: 'shown',
      label: paymentType,
    }),

  trackPaymentClick: (paymentType) =>
    ReactGA.event({
      category: 'payment',
      action: 'click',
      label: paymentType,
    }),

  trackPaymentSuccess: (paymentType) =>
    ReactGA.event({
      category: 'payment',
      action: 'success',
      label: paymentType,
    }),
};

export default GAAnalytics;
