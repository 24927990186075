import React from 'react';
import Header from '../Header';

const menuItems = [
  { label: 'Prezzi', to: '/prezzi', as: '/prezzi/', internal: true },
];

class HeaderFader extends React.PureComponent {
  static defaultProps = {
    deltaOpacity: 45,
  };

  state = {
    showHeader: false,
  };

  componentDidMount() {
    window.addEventListener('DOMMouseScroll', this.handleHeaderFade, false);
    window.addEventListener('mousewheel', this.handleHeaderFade, false);
    window.addEventListener('scroll', this.handleHeaderFade, false);
    this.handleHeaderFade();
  }

  componentWillUnmount() {
    window.removeEventListener('DOMMouseScroll', this.handleHeaderFade, false);
    window.removeEventListener('mousewheel', this.handleHeaderFade, false);
    window.removeEventListener('scroll', this.handleHeaderFade, false);
  }

  handleHeaderFade = () => {
    if (typeof window !== 'undefined') {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollY <= this.props.deltaOpacity) {
        this.setState({ showHeader: false });
      } else {
        this.setState({ showHeader: true });
      }
    }
  };

  render() {
    const { isHome = false, hideHeader, ...props } = this.props;
    const { showHeader } = this.state;

    if (hideHeader) {
      return null;
    }

    return (
      <Header
        headerFader={isHome ? true : false}
        menuItems={menuItems}
        showHeader={showHeader}
        {...props}
      />
    );
  }
}

export default HeaderFader;
