import React, { memo } from 'react';
import styled from 'styled-components';

import FlexView from 'react-flexview';
import cx from 'classnames';

import { mobileMixin } from '../../styles/themeResponsiveMixin';
import useUser from 'queries/useUser';
import Link from 'next/link';

const LoginLinkStyled = styled(FlexView)`
  ${mobileMixin`
    flex-direction: column !important;
  `}
  &.is-logged {
    ${mobileMixin`
      display: none !important;
    `}
  }
  .login-link {
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
`;

const CUSTOMER_SECTION = '/utente/panoramica';
const LAWYER_SECTION = '/prof/consulenze'; // "/avvocato/archive"; page does not exist atm
const SIGN_UP = '/signup';
const LOGIN = '/login';

function LoginLink(props) {
  const { onClick, hideSignupLink } = props;

  const { data: user } = useUser();

  const isLoggedIn = user && user.email;

  const loginLinkProps = {
    href: LOGIN.NAME,
    children: 'Login',
    onClick,
  };
  const { role } = user || {};

  const signUpLinkProps = {
    href: isLoggedIn
      ? role === 'Customer'
        ? CUSTOMER_SECTION
        : LAWYER_SECTION
      : SIGN_UP,
    'data-test-id': isLoggedIn ? 'area-personale' : 'sign-up-link',
    children: isLoggedIn ? 'Area utente' : 'Inizia ora gratis',
    onClick,
  };

  return (
    <LoginLinkStyled
      className={cx('login-link', { 'is-logged': isLoggedIn })}
      hAlignContent="center"
      vAlignContent="center"
      shrink={false}
    >
      {!isLoggedIn && (
        <Link href={LOGIN}>
          <a
            className="item"
            data-test-id="login-button"
            style={{ display: 'block' }}
            {...loginLinkProps}
          />
        </Link>
      )}
      {!(hideSignupLink && !isLoggedIn) && (
        <Link href={signUpLinkProps.href}>
          <a
            className="item login-link"
            style={{ display: 'block' }}
            {...signUpLinkProps}
          />
        </Link>
      )}
    </LoginLinkStyled>
  );
}

export default memo(LoginLink);
