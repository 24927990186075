let config = null;

if (process.env.NODE_ENV === 'production') {
  config = require('../config/production.json');
} else {
  config = require('../config/development.json');
}

export default {
  DEPLOY_URL: config.bundle.deployUrl,
  API_ENDPOINT: config.bundle.apiEndpoint,
  GA4MeasurementId: config.bundle.GA4MeasurementId,
  GATrackingNumber: config.bundle.GATrackingNumber,
  GAExperiment: config.bundle.GAExperiment,
  SendInBlueCode: config.bundle.SendInBlueCode,
  FBPixelID: config.bundle.FBPixelID,
  baremetricsTokenId: config.bundle.baremetricsTokenId,
  referralCandyAppId: config.bundle.referralCandyAppId,
  hotjarId: config.bundle.hotjarId,
  linkedinPartnerId: config.bundle.linkedinPartnerId,
  basePath: config.bundle.basePath,
  disallowRobots: config.bundle.disallowRobots,
  zohoChatEnabled: config.bundle.zohoChatEnabled,
  enableSSRCache: config.bundle.enableSSRCache,
  bingAds: config.bundle.bingAds,
  algoliaIndexesPrefix: config.bundle.algoliaIndexesPrefix,
  experimentalPushHT: config.bundle.experimentalPushHT,
  sentryDsn: config.bundle.sentryDsn,
  sentryEnv: config.bundle.sentryEnv,
  googlePayMerchantId: config.bundle.googlePayMerchantId,
  cookieYesEnvId: config.bundle.cookieYesEnvId,
  intercomId: config.bundle.intercomId,
};
