import { createGlobalStyle } from 'styled-components';
import { mobileMixin } from './themeResponsiveMixin';

const arrowSize = 5;
const tooltipBorderRadius = 5;

const GlobalStyle = createGlobalStyle`
.ui.input {
  .input-placeholder {
    font-size: 15px;
    font-style: italic;
    margin-top: 3px;
    color: #aeaeae;
  }
}

.input-error {
  .Select.dropdown,
  .checkbox input::after,
  textarea {
    border: 2px solid ${(props) => props.theme.valencia} !important;
  }

  .input-suffix {
    color: ${(props) => props.theme.valencia} !important;
  }
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #d94135;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #d94135, 0 0 5px #d94135;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  font-family: ${(props) => props.theme.fontLato};
  line-height: 1.33;
  min-width: 320px;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0, 0.8);
  background: ${(props) => props.theme.white};
}
body.body-no-overflow {
  overflow: hidden;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='date'],
select,
textarea {
  font-family: ${(props) => props.theme.fontLato};
  font-size: 14px;
  display: block;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  color: inherit;
  border: 1px solid ${(props) => props.theme.silver} !important;
  border-radius: 3px;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-appearance: inherit;
  cursor: pointer;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px ${(props) => props.theme.white} inset;
}

a {
  color: ${(props) => props.theme.valencia};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

a:hover {
  color: lighten(${(props) => props.theme.valencia}, 20%);
}

/* Site */

::-webkit-selection {
  color: rgba(0, 0, 0, 0.8);
  background-color: #cce2ff;
}

::-moz-selection {
  color: rgba(0, 0, 0, 0.8);
  background-color: #cce2ff;
}

::selection {
  color: rgba(0, 0, 0, 0.8);
  background-color: #cce2ff;
}

/* Form */

textarea::-webkit-selection,
input::-webkit-selection {
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(100, 100, 100, 0.4);
}

textarea::-moz-selection,
input::-moz-selection {
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(100, 100, 100, 0.4);
}

textarea::selection,
input::selection {
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(100, 100, 100, 0.4);
}

input::-ms-clear {
  display: none;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

p {
  margin: 0;
}

html {
  height: 100%;
}
#__next {
  height: 100%;

  #page-wrapper {
    overflow: hidden;
    width: 100%;
  }

  .page-content-wrapper {
    width: 100%;
  }
}


.layout {
  width: 100%;
  height: 100%;

  .wrapper {
    position: relative;
  }

  &.with-top-padding {
    padding-top: ${(props) => props.theme.headerHeight};
  }

}




.loading-spinner .loading-spinner-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    width: 100%
}

.loading-spinner .loading-spinner-overlay .spinner,.loading-spinner .loading-spinner-overlay .message {
    position: absolute;
    top: 50%;
    left: 50%
}

.loading-spinner .loading-spinner-overlay .spinner {
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    margin-top: -.5em;
    margin-left: -.5em;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    box-sizing: border-box
}

.loading-spinner .loading-spinner-overlay .spinner::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    margin-top: -.5em;
    margin-left: -.5em;
    border-bottom-color: transparent;
    opacity: .2
}

.loading-spinner .loading-spinner-overlay .spinner,.loading-spinner .loading-spinner-overlay .spinner::after {
    border-width: .1em;
    border-style: solid;
    border-radius: 50%;
    height: 1em;
    width: 1em
}

.loading-spinner .loading-spinner-overlay .message {
    left: 0;
    padding-right: 2em;
    padding-left: 2em;
    width: 100%;
    font-weight: 700;
    text-align: center
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999
}

.modal .modal-content {
  overflow: hidden;
  border-radius: 4px;
  min-width: 520px;
  background: #fff
}

.modal .modal-content .modal-header {
  padding: 0 15px;
  border-bottom: 1px solid #ced0da;
  min-height: 52px!important
}

.modal .modal-content .modal-header .modal-title {
  color: #354052;
  font-size: 18px;
  font-weight: 600
}

.modal .modal-content .modal-header .modal-icon-close {
  margin-left: 15px;
  cursor: pointer
}

.modal .modal-content .modal-body {
  padding: 43px 15px;
  color: #354052;
  font-size: 14px;
  font-weight: 600
}

.modal .modal-content .modal-footer {
  padding: 12px 30px;
  border-top: 1px solid #ced0da;
  background: #f0f3f8
}

.tooltip {
    z-index: 10000;
    margin-left: -1px;
    border: 1px solid;
    border-radius: 5px;
    max-width: 250px;
    text-align: center;
    word-wrap: break-word;
  }

  .tooltip.is-dark {
    border-color: transparent;
    background: #354052;
    color: #fff;
  }

  .tooltip.is-light {
    border-color: #ced0da;
    background: #fff;
    color: #354052;
  }

  .tooltip.is-small {
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .tooltip.is-big {
    padding: 17px 14px;
    font-size: 16px;
    font-weight: 400;
  }

  .tooltip:after,
  .tooltip:before {
    content: ' ';
    position: absolute;
    border: 5px solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
  }

  .tooltip:before {
    border-width: 6px;
  }

  .tooltip.position-bottom {
    margin-top: 6px;
  }

  .tooltip.position-bottom:after,
  .tooltip.position-bottom:before {
    bottom: 100%;
  }

  .tooltip.position-bottom.is-dark:after {
    border-bottom-color: #354052;
  }

  .tooltip.position-bottom.is-dark:before {
    border-bottom-color: transparent;
  }

  .tooltip.position-bottom.is-light:after {
    border-bottom-color: #fff;
  }

  .tooltip.position-bottom.is-light:before {
    border-bottom-color: #ced0da;
  }

  .tooltip.position-top {
    margin-top: -6px;
  }

  .tooltip.position-top:after,
  .tooltip.position-top:before {
    top: 100%;
  }

  .tooltip.position-top.is-dark:after {
    border-top-color: #354052;
  }

  .tooltip.position-top.is-dark:before {
    border-top-color: transparent;
  }

  .tooltip.position-top.is-light:after {
    border-top-color: #fff;
  }

  .tooltip.position-top.is-light:before {
    border-top-color: #ced0da;
  }

  .tooltip.anchor-start:after,
  .tooltip.anchor-start:before {
    left: 5px;
  }

  .tooltip.anchor-center:after,
  .tooltip.anchor-center:before {
    left: 50%;
  }

  .tooltip.anchor-center:after {
    margin-left: -5px;
  }

  .tooltip.anchor-center:before {
    margin-left: -6px;
  }

  .tooltip.anchor-end:after,
  .tooltip.anchor-end:before {
    right: 5px;
  }

  .tooltip:before {
    margin-left: -1px;
  }



  // Algolia

  .aa-Form {
    border: unset !important;

    .aa-Label svg, .aa-LoadingIndicator svg {
      color: #DEDEDE;
    }
  }

  .aa-Item {
    padding: 5px 20px;
    .aa-ItemLink {
      text-decoration: none !important;
    }



    .aa-ItemTitle {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
    }


    .aa-ItemDetails {

      ${mobileMixin`
        display: none !important;
      `}
      color: #C0C0C0;
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 19px;
      margin-left: 8px;

      -webkit-line-clamp: 1;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      text-decoration: none !important;
      max-width: 52%;
      }
    }

  .aa-Item[aria-selected=true] {
    background-color: #1A91EB;

    svg, a:hover {
      color: white !important;
    }
    .aa-ItemTitle {
      color: white;

      mark {
        color: white;
      }

      .aa-ItemDetails {
        color: #eee;
      }
    }
  }

  .aa-DetachedContainer--modal .aa-PanelLayout {
    position: relative;
  }

  .aa-DetachedSearchButton {
    border-radius: 10px !important;
    width: 500px !important;
  }
  .aa-DetachedSearchButtonIcon {
    color: #525f7f !important;
  }

  .aa-DetachedSearchButton:focus-within, .aa-Form:focus-within {
    box-shadow: 0 0 0 2px rgb(0 36 73 / 20%), inset 0 0 0 2px rgb(0 36 73 / 20%) !important;
  }
  .aa-Panel--scrollabl {
    overflow: auto;
    max-height: 472px;
    padding-bottom: 150px;


    &.loading {
      min-height: 472px;

      .loading-spinner .loading-spinner-overlay {
        max-height: 318x;
      }
    }
  }


  .aa-DetachedContainer .aa-SourceHeader {
    background-color: #F3F3F3;
    margin: unset !important;
    padding: 5px 20px;
    .aa-SourceHeaderTitleCustom {
      color: #959595;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.88px;
      line-height: 15px;
    }
  }

  .aa-DetachedSearchButton {
    &:focus {
      box-shadow: 0 0 0 2px rgb(0 36 73 / 20%), inset 0 0 0 2px rgb(0 36 73 / 20%) !important;
      border-color: unset !important;
    }
    ${mobileMixin`
      width: 100% !important;
    `}
  }


  :root {
    --aa-detached-modal-max-width: 826px;
    --aa-detached-modal-max-height: 500px;
    --aa-search-input-height: 46px;
    --aa-muted-color-rgb: 82,95,127;
    --aa-muted-color-alpha: 0.9;

  }
  a#CybotCookiebotDialogPoweredbyCybot,div#CybotCookiebotDialogPoweredByText {
    display: none;
  }

  .popover-content {
      font-size: 13px;
      font-weight: ${(props) => props.theme.bold};
      z-index: 102;
      max-width: 260px;
      padding: 4px 9px;
      text-align: center;
      color: ${(props) => props.theme.white};
      border-radius: ${tooltipBorderRadius}px;
      background-color: ${(props) => props.theme.valencia};
      box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.4);

      &::after {
        position: absolute;
        width: 0;
        height: 0;
        content: ' ';
        pointer-events: none;
        border: solid transparent;
        border-width: ${arrowSize}px;
      }

      &.position-bottom::after {
        bottom: 100%;
        border-bottom-color: ${(props) => props.theme.valencia};
      }

      &.position-top::after {
        top: 100%;
        border-top-color: ${(props) => props.theme.valencia};
      }

      &.anchor-start::after {
        left: ${tooltipBorderRadius}px;
      }

      &.anchor-center::after {
        left: 50%;
        margin-left: -${arrowSize}px;
      }

      &.anchor-end::after {
        right: ${tooltipBorderRadius}px;
      }
    }


    .ui.input {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: rgba($black, 0.8);
}

.ui.input input {
  font-family: ${(props) => props.theme.fontLato};
  line-height: 1.2142em;
  max-width: 100%;
  margin: 0;
  padding: 0.67861em 1em;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, border-color 0.2s ease;
  text-align: left;
  color: rgba(0,0,0, 0.8);
  border: 1px solid rgba(0,0,0, 0.15);
  border-radius: 0.2857rem;
  outline: none;
  background: #fff;
  box-shadow: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/* --------------------
      Placeholder
--------------------- */

/* browsers require these rules separate */

.ui.input input::-webkit-input-placeholder {
  color: rgba(0,0,0, 0.4);
}

.ui.input input::-moz-placeholder {
  color: rgba(0,0,0, 0.4);
}

.ui.input input::-ms-input-placeholder {
  color: rgba(0,0,0, 0.4);
}

/* --------------------
        Active
--------------------- */

.ui.input input:active,
.ui.input.down input {
  color: rgba(0,0,0, 0.8);
  border-color: rgba(0,0,0, 0.3);
  background: #fafafa;
  box-shadow: none;
}

/* --------------------
        Error
--------------------- */

.ui.input.error input {
  color: #d95c5c;
  border-color: #dbb1b1;
  background-color: #fff0f0;
  box-shadow: none;
}

/* Error Placeholder */

.ui.input.error input ::-webkit-input-placeholder {
  color: rgba(255, 80, 80, 0.4);
}

.ui.input.error input ::-moz-placeholder {
  color: rgba(255, 80, 80, 0.4);
}

.ui.input.error input ::-ms-input-placeholder {
  color: rgba(255, 80, 80, 0.4);
}

/* Focused Error Placeholder */

.ui.input.error input :focus::-webkit-input-placeholder {
  color: rgba(255, 80, 80, 0.7);
}

.ui.input.error input :focus::-moz-placeholder {
  color: rgba(255, 80, 80, 0.7);
}

.ui.input.error input :focus::-ms-input-placeholder {
  color: rgba(255, 80, 80, 0.7);
}

/* Focus */

.ui.icon.input > input:focus ~ i.icon {
  opacity: 1;
}

/* --------------------
        Action
--------------------- */

/* Button on Right */

.ui.action.input:not([class*='left action']) > input {
  border-right: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* --------------------
        Size
--------------------- */

.ui.input {
  font-size: 1em;
}



`;

export default GlobalStyle;
