import { APIUser } from 'lexdoit-models';
import { useQuery } from 'react-query';
import { useAxios } from '../context/AxiosContext';
import useToken from './useToken';

export default function useUser() {
  // Get our Axios instance from our previously created Hook
  const axios = useAxios();
  const token = useToken();

  // Create a query with the key `projects`
  return useQuery<APIUser>(
    ['user'],
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get('/users/getUser');

      // Return the data from the Axios response
      return data;
    },
    {
      enabled: !!token,
      staleTime: 30 * 1000,
      retry: false,
    }
  );
}
