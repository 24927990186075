import React from 'react';
import capitalize from 'lodash/capitalize';

import FlexView from 'react-flexview';
import Link from 'next/link';

import FullWidthPageSection from '../FullWidthPageSection';

import footerData from './footer.json';

const { socials, products } = footerData;

import bandoImgNew from '../../public/png/bando-intraprendo-new-white.png';
import logoFooter from '../../public/png/logo-footer.png';
import creditCards from '../../public/png/credit_cards.png';
import goDaddy from '../../public/png/godaddy.png';

import styled from 'styled-components';
import { mobileMixin, tabletMixin } from '../../styles/themeResponsiveMixin';
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin } from 'react-icons/io5';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  LAWYER_CONSULTANCY,
  CUSTOMER_CONSULTANCY_ROUTE,
  CUSTOMER_ACCOUNTANTS,
} from '../../constants/routes';
import useUser from 'queries/useUser';

const FooterStyled = styled(FullWidthPageSection)`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 15px;
  background: ${(props) => props.theme.daintree};
  &.no-mobile {
    ${mobileMixin`
      display: none !important;
    `}
  }
`;

const FooterSection = styled(FlexView)`
  > div {
    padding: 40px 30px;

    ${mobileMixin`
        padding: 20px 15px;
      `}
  }
`;

const LinksSection = styled(FlexView)`
  ${tabletMixin`
    flex-direction: column !important;
  `}
  .links-section-wrapper {
    ${mobileMixin`
      flex-direction: column !important;
    `}
  }

  img.logo {
    ${mobileMixin`
      display: none !important;
    `}

    ${tabletMixin`
      display: none !important;
    `}

    width: 55px;
    margin-right: 100px;
  }
`;

const SectionStyled = styled(FlexView)`
  &:last-child {
    ${mobileMixin`
      margin-top: 20px;
    `}
  }

  .header {
    margin-bottom: 25px;

    ${mobileMixin`
      margin-bottom: 10px;
    `}
  }
`;

const SectionItems = styled(FlexView)`
  .row {
    ${mobileMixin`
      margin-bottom: 0;
      a {
        margin-bottom: 5px;
      }
    `}

    a {
      width: 160px;
      color: ${(props) => props.theme.white};
      &:not(&:last-child) {
        margin-bottom: 7px;
      }
    }
  }

  .social-items {
    .item {
      width: 36px;
      height: 36px;
      margin: 0 10px 10px 0;
      cursor: pointer;
      border: 2px solid rgba(255, 255, 255, 0.6);
      border-radius: 36px;

      &:hover {
        color: ${(props) => props.theme.white};
        border-color: ${(props) => props.theme.white};

        a {
          color: ${(props) => props.theme.white};
        }
      }

      a {
        color: rgba(255, 255, 255, 0.6);
        height: 16px;

        svg {
          margin: 0;
        }
      }
    }
  }
`;

const LogosStyled = styled(FlexView)`
  ${mobileMixin`
    display: none !important;
  `}

  ${tabletMixin`
    display: none !important;
  `}

  img {
    &.credit-cards {
      width: auto !important;
      height: 26px;
      object-fit: cover;
    }

    &.godaddy-logo {
      width: 159px;
      height: 37px;
      margin-top: 5px;
      object-fit: cover;
    }
  }
`;

const CompetitionSectionStyled = styled(FlexView)`
  border-top: 1px dotted ${(props) => props.theme.greyish};
  border-bottom: 1px dotted ${(props) => props.theme.greyish};
  padding: 20px !important;

  ${tabletMixin`
    display: none !important;
  `}

  ${mobileMixin`
    display: none !important;
  `}
`;

const ProductSectionStyled = styled(FlexView)`
  ${mobileMixin`
    padding-top: 15px !important;
    flex-direction: column !important;
  `}
  padding-top: 38px;

  ${tabletMixin`
    width: 50% !important;
  `}
`;

const ProductsColumnWrapper = styled(FlexView)`
  justify-content: space-around;

  ${mobileMixin`
    flex-direction: column !important;
  `}

  ${tabletMixin`
    flex-wrap: wrap !important;

    .products-section:last-child {
      width: 100% !important;
      flex-direction: row !important;
    }
  `}
`;

const ProductStyled = styled(FlexView)`
  ${mobileMixin`
    width: 100% !important;
    margin-bottom: 20px !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  `}

  margin-bottom: 40px;
  color: ${(props) => props.theme.greyish};

  .header {
    margin-bottom: 23px;
    font-weight: ${(props) => props.theme.semibold};
    text-transform: uppercase;
    font-size: 14px;

    ${mobileMixin`
      margin-bottom: 10px !important;
    `}
  }

  .items a {
    margin-bottom: 8px;
    font-size: 14px;
    color: ${(props) => props.theme.greyish};
  }
`;

const PrivacyPolicyStyled = styled(FlexView)`
  padding: 16px;
  padding-right: 80px;
  color: ${(props) => props.theme.alto};
  font-size: 14px;

  // mobileAndTabletMixin
  @media (max-width: 899px) {
    justify-content: flex-start !important;
  }

  a {
    margin: 0 3px;
    color: ${(props) => props.theme.alto};
  }
`;

function chunkKeyGetter(chunks) {
  return chunks
    .map(({ label }) => label)
    .join('-')
    .replace(/\s/g, '-');
}

const sections = [
  {
    title: 'LEXDOIT',
    leftItems: [
      {
        href: '/',
        label: 'Home',
      },
      {
        as: '/d/aprire-nuova-attivita/',
        href: '/d/[tag]',
        label: 'Guide e FAQ',
      },
      {
        href: '/blog/',
        label: 'Blog',
        external: true,
      },
    ],
    rightItems: [
      {
        href: '/lavora-con-noi/',
        label: 'Lavora con Noi',
      },
      {
        href: 'https://www.lexdo.it/partnership/',
        label: 'Partnership',
        external: true,
      },

      {
        href: '/recensioni-chi-siamo',
        as: '/recensioni-chi-siamo/',
        label: 'Recensioni',
      },
    ],
    itemsRenderer: ({ leftItems, rightItems }) => {
      return (
        <FlexView>
          <FlexView className="row" column>
            {leftItems.map(({ href, label, external, as }) =>
              external ? (
                <a key={label} href={href} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ) : (
                <Link href={href} key={label} as={as}>
                  {label}
                </Link>
              )
            )}
          </FlexView>
          <FlexView className="row" column>
            {rightItems.map(({ href, label, external, as }) =>
              external ? (
                <a key={label} href={href} target="_blank" rel="noreferrer">
                  {label}
                </a>
              ) : (
                <Link href={href} key={label} as={as}>
                  {label}
                </Link>
              )
            )}
          </FlexView>
        </FlexView>
      );
    },
  },
  {
    title: 'SEGUICI',
    items: socials,
    itemsRenderer: (
      { items } // eslint-disable-line react/display-name
    ) => (
      <FlexView className="social-items" wrap>
        {items.map(({ name, url }) => (
          <FlexView
            className="item"
            key={name}
            hAlignContent="center"
            vAlignContent="center"
            shrink={false}
          >
            <a href={url} target="_blank" rel="noreferrer">
              {name === 'twitter' ? (
                <IoLogoTwitter />
              ) : name === 'facebook' ? (
                <IoLogoFacebook />
              ) : (
                <IoLogoLinkedin />
              )}
            </a>
          </FlexView>
        ))}
      </FlexView>
    ),
  },
];

class Footer extends React.PureComponent {
  getLocals(props) {
    return {
      ...props,
      fullWidthPageSectionProps: {
        backgroundType: 'Color',
        backgroundColor: '#002834', // daintree
        column: true,
        shrink: false,
      },
    };
  }

  templateProducts = ({ category, documents }) => (
    <ProductStyled className="products" key={category} column>
      <div className="header">{category}</div>
      <FlexView className="items" column>
        {documents.map(({ title, type, url }, i) =>
          type === 'document' ? (
            <a key={i} href={`/modello/${url}/`}>
              {capitalize(title)}
            </a>
          ) : (
            <a key={i} href={`/consulenze/${url}/`}>
              {capitalize(title)}
            </a>
          )
        )}
      </FlexView>
    </ProductStyled>
  );

  templateSection = ({
    title,
    leftItems,
    rightItems,
    items,
    itemsRenderer,
  }) => (
    <SectionStyled className="column" column key={title}>
      <div className="header">{title}</div>
      <SectionItems className="items" column>
        {itemsRenderer({ leftItems, rightItems, items })}
      </SectionItems>
    </SectionStyled>
  );

  render() {
    const { bigFooter, pathname } = this.props;

    return (
      <FooterStyled
        className={`footer big ${
          pathname === CUSTOMER_ACCOUNTANTS ? 'no-mobile' : ''
        }`}
        backgroundType="Color"
        backgroundColor="#002449"
        column
        shrink={false}
      >
        {bigFooter ? (
          <FooterSection className="sections" column>
            {/* FIRST ROW */}
            <LinksSection className="links-section" grow wrap shrink={false}>
              <FlexView shrink={false} className="links-section-wrapper">
                <FlexView shrink={false} style={{ marginRight: 100 }}>
                  <Image
                    width={55}
                    height={55}
                    className="logo lazyload"
                    src={logoFooter}
                    alt="LexDo.it"
                    layout="fixed"
                  />
                </FlexView>
                {sections.map(this.templateSection)}
              </FlexView>
              <LogosStyled className="logos" hAlignContent="right" column grow>
                <Image
                  className="credit-cards"
                  src={creditCards}
                  width={300}
                  height={26}
                  alt="carte di credito"
                />
                <Image
                  className="godaddy-logo"
                  src={goDaddy}
                  width={159}
                  height={37}
                  alt="verificato e protetto go daddy"
                />
              </LogosStyled>
            </LinksSection>
            <CompetitionSectionStyled
              grow
              shrink={false}
              hAlignContent="center"
              className="competitions-section"
            >
              <a href="/recensioni-chi-siamo/">
                <Image
                  className="bando-intraprendo"
                  src={bandoImgNew}
                  alt="bando intraprendo regione lombardia"
                  height={100}
                  objectFit="contain"
                />
              </a>
            </CompetitionSectionStyled>
            {/* SECOND ROW */}
            <ProductsColumnWrapper>
              {products.map((productColumn, i) => {
                return (
                  <ProductSectionStyled
                    key={i}
                    className="products-section"
                    column
                  >
                    {productColumn.map(this.templateProducts)}
                  </ProductSectionStyled>
                );
              })}
            </ProductsColumnWrapper>
          </FooterSection>
        ) : null}

        <PrivacyPolicyStyled
          className="privacy-policy"
          hAlignContent="center"
          shrink={false}
          grow
          wrap
        >
          Copyright &copy; {new Date().getFullYear()} LexDo.it Srl - Via San
          Tomaso 6, Milano 20121 - P.IVA 09021520961 - Tutti i diritti riservati
          -
          <a rel="nofollow" href="/termini-di-utilizzo/">
            Termini e condizioni
          </a>
          <a href="/privacy-policy/" rel="nofollow">
            Privacy Policy
          </a>
          <a href="/cookie-policy/" rel="nofollow">
            Rivedi le tue scelte sui cookie
          </a>
        </PrivacyPolicyStyled>
      </FooterStyled>
    );
  }
}

export default function FooterHOC(props) {
  const router = useRouter();
  const { data: user } = useUser();
  const { pathname } = router;

  const pathParts = pathname.split('/');
  const isSummary =
    pathParts.length > 1 && pathParts[pathParts.length - 1] === 'summary';

  if (
    (pathname === LAWYER_CONSULTANCY &&
      (user?.role === 'Lawyer' || user?.role === 'Accountant')) ||
    pathname === CUSTOMER_CONSULTANCY_ROUTE
  ) {
    return null;
  }

  return <Footer bigFooter={isSummary} pathname={pathname} />;
}
