import variables from 'styles/themeVariables';

interface IProps {
  isWhite?: boolean;
  isCustom?: boolean;
  color?: string;
  color2?: string;
}

export default function IconPanoramica(props: IProps) {
  const { isWhite, isCustom, color, color2 } = props;

  return (
    <svg
      width="24px"
      height="21px"
      viewBox="0 0 24 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>panoramica - icon - default</title>
      <g
        id="visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu---default"
          transform="translate(-34.000000, -105.000000)"
          fill={
            isWhite
              ? variables.white
              : isCustom
              ? color2
              : variables.iconsDarkGray
          }
          fillRule="nonzero"
        >
          <g id="panoramica" transform="translate(9.000000, 96.000000)">
            <g
              id="panoramica---icon---default"
              transform="translate(25.354189, 9.222250)"
            >
              <path
                d="M11.538514,0 C5.16639859,0 2.61032871e-05,5.21875 2.61032871e-05,11.6615385 C-0.00598104273,14.5064648 1.02501594,17.2560749 2.90005244,19.3956731 C2.9567832,19.4581731 3.00822551,19.5206731 3.06495628,19.5778846 C3.5192832,20 3.65726397,20 4.0178409,20 C4.37841782,20 4.61543705,20 4.97601397,19.5730769 C6.53851397,17.7230769 8.94236013,16.9230769 11.538514,16.9230769 C14.1346678,16.9230769 16.5418794,17.7264423 18.101014,19.5730769 C18.4615909,20 18.6933217,20 19.0591871,20 C19.4250524,20 19.6024563,20 20.0120717,19.5778846 C20.0707255,19.5177885 20.1202447,19.4581731 20.1769755,19.3956731 C22.052012,17.2560749 23.083009,14.5064648 23.0770018,11.6615385 C23.0770018,5.21875 17.9106294,0 11.538514,0 Z M10.7692832,3.07692308 L12.3077447,3.07692308 L12.3077447,6.15384615 L10.7692832,6.15384615 L10.7692832,3.07692308 Z M6.15389859,12.3076923 L3.07697551,12.3076923 L3.07697551,10.7692308 L6.15389859,10.7692308 L6.15389859,12.3076923 Z M7.18707167,8.275 L5.0115909,6.09903846 L6.0990909,5.01153846 L8.27505244,7.18701923 L7.18707167,8.275 Z M12.6250524,12.4711538 C12.5316245,12.6006363 12.4179965,12.7142643 12.288514,12.8076923 C11.6379688,13.2615282 10.7433506,13.1073082 10.2823089,12.4618498 C9.82126716,11.8163914 9.96552979,10.9201136 10.6058217,10.4519231 L14.6154371,8.46153846 L12.6250524,12.4711538 Z M14.8019755,7.18701923 L16.9779371,5.01153846 L18.0654371,6.09903846 L15.8899563,8.275 L14.8019755,7.18701923 Z M20.0000524,12.3076923 L16.9231294,12.3076923 L16.9231294,10.7692308 L20.0000524,10.7692308 L20.0000524,12.3076923 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
