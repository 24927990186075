import * as React from 'react';
import { dynamicInjectScripts } from '../../utils/ThirdPartyScriptsUtil';

declare global {
  interface Window {
    Calendly?: {
      initPopupWidget: (obj: any) => unknown;
      closePopupWidget: () => unknown;
    };

    uetq?: {
      push: any;
    };

    fbq?: any;
    sendinblue?: any;
    hj?: any;
  }
}

const CalendlyScript = `
<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
<script type="text/javascript">
   var script = document.createElement('script');
   script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
   script.setAttribute('type', 'text/javascript');
   document.getElementsByTagName('head')[0].appendChild(script);
</script>

`;

class CalendlyHelper extends React.PureComponent {
  render() {
    if (typeof window !== 'undefined' && !window.Calendly) {
      dynamicInjectScripts(CalendlyScript);
    }
    return null;
  }
}

export default CalendlyHelper;
