import React, { memo } from 'react';
import FlexView from 'react-flexview';

import styled from 'styled-components';
import { mobileMixin } from 'styles/themeResponsiveMixin';

const ExpandableMenuMobileSectionEl = styled(FlexView)`
  ${mobileMixin`
    text-align: left;
  `}

  &.is-expanded {
    .expandable-menu-mobile-section-wrapper {
      max-height: 900px;
      opacity: 1;
    }
  }

  .title {
    width: 100%;
    font-size: 18px;
    color: ${(props) => props.theme.black};
    padding: 15px 0 10px;

    &-open {
      border-bottom: unset !important;
    }

    .arrow {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      position: relative;
      margin: 0 5px;

      span {
        top: 0.5rem;
        position: absolute;
        width: 0.65rem;
        height: 0.1rem;
        background-color: ${(props) => props.theme.black};
        display: inline-block;
        transition: all 0.2s ease;

        &:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }

        &:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }
      }

      &.arrow-closed {
        span {
          &:first-of-type {
            transform: rotate(-45deg);
          }

          &:last-of-type {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .expandable-menu-mobile-section-wrapper {
    transition: all 100ms ease-in-out;
    padding-left: 15px;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;

    .item {
      font-size: 16px !important;
      padding: 8px 0 !important;
      font-weight: normal !important;
    }
  }
`;

type IExpandableMenuMobileSectionProps = {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: JSX.Element;
};

function ExpandableMenuMobileSection(
  props: React.PropsWithChildren<IExpandableMenuMobileSectionProps>
) {
  const { title, isExpanded, onToggle, children } = props;
  return (
    <ExpandableMenuMobileSectionEl
      className={`expandable-menu-mobile-section ${
        isExpanded ? 'is-expanded' : ''
      }`}
      column
      shrink={false}
    >
      <FlexView
        vAlignContent="center"
        className={`title ${isExpanded ? 'title-open' : ''}`}
        onClick={onToggle}
      >
        {title}
        <span className={`arrow ${!isExpanded ? '' : 'arrow-closed'}`}>
          <span />
          <span />
        </span>
      </FlexView>
      <FlexView className="expandable-menu-mobile-section-wrapper" column>
        {children}
      </FlexView>
    </ExpandableMenuMobileSectionEl>
  );
}

export default memo(ExpandableMenuMobileSection);
