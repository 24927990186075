import React, { PureComponent, ReactChildren } from 'react';
import FlexView from 'react-flexview';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import { Children } from 'buildo-react-components/lib/utils';

interface IFullWidthPageSectionDivProps {
  $noColor: boolean;
}

const FullWidthPageSectionDiv = styled(FlexView)<IFullWidthPageSectionDivProps>`
  width: 100%;
  ${(props) => {
    if (!props.$noColor) {
      return css`
        color: ${(props) => props.theme.white};
      `;
    }
  }}
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;

  padding: 0 20px !important;
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
  top: 0;
  left: 0;
`;

const Content = styled(FlexView)`
  width: 100%;
  max-width: ${(props) => props.theme.pageContentWidth};
  z-index: 1;
`;

type IFullWidthPageSection = {
  className?: string;
  hAlignContent?: 'left' | 'center' | 'right';
  vAlignContent?: 'top' | 'center' | 'bottom';
  column?: boolean;
  shrink?: boolean;
  wrap?: boolean;
  grow?: boolean;
  backgroundType: 'Color' | 'Image';
  backgroundColor?: string;
  backgroundUrl?: string;
  backgroundRetinaUrl?: string;
  disableLazyLoad?: boolean;
  noColor?: boolean;
  children: Children;
};

class FullWidthPageSection extends PureComponent<IFullWidthPageSection> {
  static defaultProps = {
    column: false,
    wrap: false,
    shrink: true,
  };

  isRetina = () => {
    return false;
  };

  render() {
    const {
      className,
      hAlignContent,
      vAlignContent,
      column,
      shrink,
      wrap,
      grow,
      children,
      backgroundType,
      backgroundUrl,
      backgroundRetinaUrl,
      backgroundColor,
      disableLazyLoad,
      noColor,
    } = this.props;

    const style = backgroundColor ? { backgroundColor } : undefined;

    return (
      <FullWidthPageSectionDiv
        className={cx('full-width-page-section', className)}
        vAlignContent="center"
        hAlignContent="center"
        style={style}
        shrink={false}
        $noColor={noColor}
      >
        {backgroundType === 'Image' ? (
          <BackgroundImage
            data-srcset={`${backgroundUrl} 1x, ${backgroundRetinaUrl} 2x`}
            data-src={`${backgroundUrl}`}
            src={disableLazyLoad ? `${backgroundUrl}` : undefined}
            srcSet={
              disableLazyLoad
                ? `${backgroundUrl} 1x, ${backgroundRetinaUrl} 2x`
                : undefined
            }
            className={!disableLazyLoad ? 'lazyload' : ''}
          />
        ) : null}
        <Content
          className="container"
          hAlignContent={hAlignContent}
          vAlignContent={vAlignContent}
          column={column}
          shrink={shrink}
          wrap={wrap}
          grow={grow}
        >
          {children}
        </Content>
      </FullWidthPageSectionDiv>
    );
  }
}
export default FullWidthPageSection;
