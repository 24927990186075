import { css } from 'styled-components';
import v from './themeVariables';
const { tabletWidth, desktopWidth } = v;

export const mobileMixin = (...args) => css`
  @media (max-width: ${`${(tabletWidth - 1).toString()}px`}) {
    ${css(...args)};
  }
`;

export const tabletMixin = (...args) => css`
  @media (min-width: ${`${tabletWidth}px`}) and (max-width: ${`${
      desktopWidth - 1
    }px`}) {
    ${css(...args)};
  }
`;

export const mobileAndTabletMixin = (...args) => css`
  @media (max-width: ${`${desktopWidth - 1}px`}) {
    ${css(...args)};
  }
`;

export const desktopMixin = (...args) => css`
  @media (min-width: ${`${desktopWidth}px`}) {
    ${css(...args)};
  }
`;

export const image2x = (file, type, width, height) => css`
  background-image: url('${file}.${type}');
  background-size: ${width} ${height};
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    & {
      background-image: url('${file}@2x.${type}');
      -webkit-background-size: ${width} ${height};
      -moz-background-size: ${width} ${height};
      -o-background-size: ${width} ${height};
      background-size: ${width} ${height};
    }
  }
`;

export const image3x = (file, type, width, height) => css`
  background-image: url(${file}+${type});
  background-size: ${width} ${height};
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (-moz-min-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    & {
      background-image: url(${file}+'@3x.' + ${type});
      -webkit-background-size: ${width} ${height};
      -moz-background-size: ${width} ${height};
      -o-background-size: ${width} ${height};
      background-size: ${width} ${height};
    }
  }
`;

export const icon2x = (imageName, type, width, height = 'auto') => css`
  i.icon.${imageName} {
    width: ${width};
    height: ${height};

    ${image2x(`/${type}/${imageName}`, type, width, height)}
  }
`;
