import includes from 'lodash/includes';
import findKey from 'lodash/findKey';
import values from 'lodash/values';

export const urls = {
  'contratto-di-agenzia': 1,
  'accordo-di-riservatezza-non-disclosure-agreement': 2,
  'contratto-di-locazione-ad-uso-abitativo': 3,
  'contratto-di-locazione-commerciale-ad-uso-diverso-abitativo': 4,
  'patto-di-non-concorrenza': 5,
  'contratto-di-locazione-ad-uso-turistico': 6,
  'contratto-comodato-di-uso-gratuito': 7,
  'contratto-di-utilizzo-spazi-servizi': 8,
  'contratto-rent-to-buy-affitto-con-riscatto': 9,
  'contratto-di-lavoro-dipendente': 10,
  'contratto-di-lavoro-dirigente': 11,
  'contratto-colf-e-badanti': 12,
  'contratto-colf-e-badanti-sitterlandia': 12,
  'contratto-lavoro-chiamata-intermittente': 13,
  'lettera-di-intenti': 20,
  'contratto-procacciatore-di-affari': 21,
  'contratto-di-appalto-prestazione-opera': 22,
  'contratto-fornitura-servizio': 23,
  'lettera-sollecito-pagamento': 24,
  'contratto-prestito-tra-privati': 25,
  'diffida-ad-adempiere': 26,
  'quietanza-pagamento': 27,
  'termini-condizioni-sito-web-app': 29,
  'riconoscimento-di-debito': 28,
  'privacy-policy': 30,
  'cookie-policy-sito-web': 31,
  'contratto-cessione-sito-web': 32,
  'joint-venture-contrattuale': 33,
  'contratto-distribuzione-commerciale': 34,
  'liberatoria-fotografica-riprese-video': 35,
  'term-sheet-contratto-investimento': 36,
  'contratto-licenza-di-uso': 37,
  'contratto-estimatorio-conto-vendita': 38,
  'lettera-recesso-risoluzione': 39,
  'condizioni-di-adesione-e-marketplace': 40,
  'contratto-affitto-ramo-azienda': 41,
  'termini-condizioni-fornitore-terzo': 42,
  'registro-trattamenti-dati-personali-gdpr-privacy': 43,
  'informativa-privacy-consenso-attivita-offline': 44,
  'contratto-di-noleggio-locazione-beni-mobili': 45,
  'patti-parasociali-srl-spa': 46,
  'testamento-olografo': 50,
  'biotestamento-testamento-biologico': 51,
  'statuto-atto-costitutivo-srl': 100,
  'statuto-srl-semplificata': 101,
  'contratto-di-sponsorizzazione': 47,
  'contratto-franchising-affiliazione-commerciale': 48,
  'contratto-collaborazione-continuativa-coordinata-cococo': 49,
  'contratto-compravendita-beni-mobili': 52,
  'contratto-somministrazione-beni': 53,
  'contratto-di-pubblicita': 54,
  'contratto-preliminare-compravendita-immobiliare': 55,
  'contratto-preliminare-cessione-quote-azioni': 56,
  'contratto-cessione-proprieta-intellettuale': 57,
  'lettera-di-dimissioni': 58,
  'contratto-locazione-uso-foresteria': 59,
  'statuto-atto-costitutivo-associazione': 60,
  'contratto-cessione-credito': 61,
  'regolamento-work-for-equity': 62,
  'contratto-deposito-merci': 63,
  'contratto-associazione-in-partecipazione': 64,
  'lettera-richiamo-disciplinare-dipendente': 65,
  'lettera-manleva-scarico-responsabilita': 66,
  'lettera-dimissioni-amministratore-srl-spa': 67,
  'lettera-licenziamento-dipendente': 68,
  'lettera-licenziamento-disciplinare': 69,
  'verbale-assemblea-ordinaria': 70,
  'nomina-responsabile-trattamento': 71,
};

export const getDocumentKindIdByHyphens = (hyphens: string) => {
  if (includes(Object.keys(urls), hyphens)) {
    return urls[hyphens];
  }
  return null;
};

export const getDocumentKindHyphensUrlById = (documentKindId: number) => {
  if (includes(values(urls), documentKindId)) {
    return findKey(urls, (u) => u === documentKindId);
  }
  return null;
};

export const getLinksByDocumentKindId = (documentKindId: number) => {
  const getSpecificMeta = () => {
    switch (documentKindId) {
      case 12:
      case 13:
        return [
          {
            rel: 'canonical',
            href: 'https://www.lexdo.it/document/contratto-colf-e-badanti',
          },
        ];
      default:
        return [];
    }
  };

  return getSpecificMeta();
};

export const getCanonicalUrl = () => {
  if (typeof window !== 'undefined') {
    const { pathname } = window.location;
    let canonicalURL = `https://www.lexdo.it${pathname}`;
    if (pathname.charAt(pathname.length - 1) !== '/') {
      canonicalURL = `${canonicalURL}/`;
    }
    return { rel: 'canonical', href: canonicalURL.toLowerCase() };
  }
  return {};
};

export const getCanonicalUrlWithoutApp = () => {
  if (typeof window !== 'undefined') {
    const { pathname } = window.location;
    let canonicalURL = `https://www.lexdo.it${pathname}`;
    canonicalURL = canonicalURL.replace('/app/', '/');
    if (pathname.charAt(pathname.length - 1) !== '/') {
      canonicalURL = `${canonicalURL}/`;
    }
    return { rel: 'canonical', href: canonicalURL.toLowerCase() };
  }
  return {};
};
