import { useDoLogout } from 'mutations/useDoLogout';
import { useRouter } from 'next/router';
import useUser from 'queries/useUser';
import React, { memo } from 'react';
import FlexView from 'react-flexview';
import { CUSTOMER_OVERVIEW } from 'constants/routes';

import styled from 'styled-components';
import useSubscriptionStatusInfo from '../../../queries/useSubscriptionStatusInfo';

type IAccountAreaHeaderNavigationProps = {
  templateMenuItem: ({
    label,
    to,
  }: {
    label: string;
    to: string;
  }) => JSX.Element;
  isExpanded: boolean;
};

const CustomerMenuOptions = [
  { to: '/utente/nuove-attivita/', label: 'Archivio Nuove Attività' },
  { to: '/utente/archive/', label: 'Archivio Contratti' },
  { to: '/utente/consulenze/', label: 'Archivio Consulenze' },
  { to: '/utente/dati-piano/', label: 'Servizio Legale' },
  { to: '/utente/servizio-contabilita', label: 'Servizio Contabilità' },
  {
    to: '/web/utente/pagamenti-e-fatture/',
    label: 'Dati di Contatto e Pagamento',
  },
];

const LawyerMenuOptions = [
  { to: '/utente/consulenze/', label: 'Consulenze' },
  { to: '/utente/archive/', label: 'Archivio' },
];

const AccountAreaHeaderNavigationEl = styled(FlexView)`
  &.is-expanded {
    .account-area-header-navigation-wrapper {
      max-height: 900px;
      opacity: 1;
    }
  }

  .title {
    width: 100%;
    color: ${(props) => props.theme.black};
    padding: 15px 0;
    font-size: 18px;

    .arrow {
      width: 1rem;
      height: 1rem;
      display: inline-block;
      position: relative;
      margin: 0 5px;
      color: black;

      span {
        top: 0.5rem;
        position: absolute;
        width: 0.65rem;
        height: 0.1rem;
        background-color: ${(props) => props.theme.black};
        display: inline-block;
        transition: all 0.2s ease;

        &:first-of-type {
          left: 0;
          transform: rotate(45deg);
        }

        &:last-of-type {
          right: 0;
          transform: rotate(-45deg);
        }
      }

      &.arrow-closed {
        span {
          &:first-of-type {
            transform: rotate(-45deg);
          }

          &:last-of-type {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .account-area-header-navigation-wrapper {
    transition: all 100ms ease-in-out;
    padding-left: 20px;
    max-height: 0px;
    opacity: 0;
    overflow: hidden;

    .item {
      font-size: 16px !important;
      font-weight: normal !important;
    }
  }
`;

function AccountAreaHeaderNavigation({
  templateMenuItem,
  isExpanded,
}: IAccountAreaHeaderNavigationProps) {
  const { data: apiUser } = useUser();
  const router = useRouter();

  const doLogout = useDoLogout();
  const logout = () => {
    doLogout.mutateAsync();
    return Promise.resolve();
  };

  return (
    <AccountAreaHeaderNavigationEl
      className={`account-area-header-navigation ${
        isExpanded ? 'is-expanded' : ''
      }`}
      column
      shrink={false}
    >
      <FlexView
        vAlignContent="center"
        className={`title ${isExpanded ? 'title-open' : ''}`}
        onClick={() => router.push(CUSTOMER_OVERVIEW)}
        shrink={false}
      >
        Area utente
      </FlexView>
    </AccountAreaHeaderNavigationEl>
  );
}

export default memo(AccountAreaHeaderNavigation);
