import { APIDocument } from 'lexdoit-models';
import { useQuery } from 'react-query';
import { useAxios } from '../context/AxiosContext';

export default function useDocumentByHash(urlHash: string) {
  // Get our Axios instance from our previously created Hook
  const axios = useAxios();

  // Create a query with the key `projects`
  return useQuery<APIDocument>(
    ['documentByHash', urlHash],
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get<APIDocument>(
        '/documents/findByUrlHash',
        {
          params: {
            urlHash,
          },
        }
      );

      // Return the data from the Axios response
      return data;
    },
    {
      enabled: !!urlHash,
    }
  );
}
