import { useRouter } from 'next/router';
import Script from 'next/script';
import useUser from 'queries/useUser';
import React from 'react';
import AppConstants from 'utils/AppConstants';
import { dynamicInjectScripts } from 'utils/ThirdPartyScriptsUtil';

declare global {
  interface Window {
    barepay?: any;
  }
}

const baremetricsHelper = (accessTokenId, customerId) => {
  return `
  <barepay></barepay>
  <script src="https://baremetrics-dunning.baremetrics.com/js/application.js" async="true"></script>

  <script>
  window.barepay = {created: true};
  window.barepay.params = {
    access_token_id: "${accessTokenId}",
    customer_oid: "${customerId}"
  };

  </script>`;
};

export default function ScriptWrapper() {
  const { data: user } = useUser();
  const router = useRouter();

  return (
    <>
      {AppConstants.FBPixelID && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', "${AppConstants.FBPixelID}"); // Insert your pixel ID here.
    fbq('track', 'PageView');`,
          }}
        ></script>
      )}

      {AppConstants.baremetricsTokenId &&
        !!user &&
        !!user.paymentCustomerId &&
        !window.barepay &&
        router.asPath !== '/utente/pagamenti-e-fatture/' &&
        router.asPath !== '/abbonati/' &&
        router.asPath !== '/upgrade/' &&
        dynamicInjectScripts(
          baremetricsHelper(
            AppConstants.baremetricsTokenId,
            user.paymentCustomerId
          )
        )}

      {AppConstants.linkedinPartnerId && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `_linkedin_partner_id = "${AppConstants.linkedinPartnerId}";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            </script><script type="text/javascript">
            (function(){var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})();
            </script>
            <noscript>
            <img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=${AppConstants.linkedinPartnerId}&fmt=gif" />
            </noscript>`,
          }}
        ></script>
      )}

      {AppConstants.linkedinPartnerId && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `_linkedin_partner_id = "${AppConstants.linkedinPartnerId}";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            </script><script type="text/javascript">
            (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
            </script>
            <noscript>
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${AppConstants.linkedinPartnerId}&fmt=gif" />
            </noscript>`,
          }}
        ></script>
      )}

      {AppConstants.hotjarId && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: ` (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${AppConstants.hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        ></script>
      )}

      {AppConstants.bingAdsCode && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${AppConstants.bingAdsCode}"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
          }}
        ></script>
      )}
    </>
  );
}
