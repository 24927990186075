import { Button, Spacer, Text } from 'ds';
import React from 'react';
import FlexView from 'react-flexview/lib';
import Link from 'next/link';
import { IoArrowForwardSharp } from 'react-icons/io5';
import { LinksColumn, NavigationAttivita, RightSide, LeftSide } from './shared';

import styled from 'styled-components';

import dynamic from 'next/dynamic';

// const Autocomplete = dynamic(() =>
//   import('components/Search/Autocomplete').then(mod => mod.Autocomplete)
// );

const ColumnWrapper = styled(FlexView)`
  justify-content: space-between;
`;

export default function NavigationSubMenuProducts() {
  return (
    <NavigationAttivita grow>
      <FlexView grow className="content-wrapper">
        <RightSide column shrink={false} width="70%">
          <ColumnWrapper grow>
            <FlexView column>
              <Text fontWeight="900" variant="body">
                Contratti su misura online
              </Text>
              <Text fontSize="2" color="#838383" variant="body">
                Crea 200+ tipi di contratti e documenti in pochi minuti
              </Text>
              <Spacer variant="small" />
              <LinksColumn column>
                <a href="/modello/contratto-di-appalto-prestazione-opera/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    {"Realizzazione d'opera"}
                  </Text>
                </a>
                <a href="/modello/contratto-fornitura-servizio/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Fornitura di servizio
                  </Text>
                </a>
                <a href="/modello/accordo-di-riservatezza-non-disclosure-agreement/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Accordo di riservatezza
                  </Text>
                </a>
                <a href="/modello/termini-condizioni-sito-web-app/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Termini e condizioni sito
                  </Text>
                </a>
                <a href="/modello/privacy-policy/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Privacy policy
                  </Text>
                </a>
                <a href="/prodotti/">
                  <Button
                    variant="secondary"
                    size="medium"
                    icon={<IoArrowForwardSharp />}
                    iconOnRight
                    className="btn"
                    fontSize="1"
                  >
                    Vedi lista completa
                  </Button>
                </a>
              </LinksColumn>
            </FlexView>

            <FlexView column>
              <Text fontWeight="900" variant="body">
                Consulenze legali con esperti
              </Text>
              <Text fontSize="2" color="#838383" variant="body">
                Parla con professionisti esperti per qualsiasi esigenza
              </Text>
              <Spacer variant="small" />
              <LinksColumn column grow>
                <a href="/consulenza-legale-online/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Consulenza legale
                  </Text>
                </a>
                <a href="/consulenza-legale-online/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Revisione di contratti
                  </Text>
                </a>
                <a href="/consulenze/registrazione-marchio/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Registrazione marchio
                  </Text>
                </a>
                <a href="/consulenze/consulenza-GDPR-privacy-dati-personali/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Adeguamento GDPR
                  </Text>
                </a>
                <a href="/consulenze/work-for-equity-startup-innovative-srl-spa/">
                  <Text fontSize="2" color="#2E3132" variant="body">
                    Work for equity
                  </Text>
                </a>

                <a href="/consulenza-legale-online/">
                  <Button
                    variant="secondary"
                    size="medium"
                    icon={<IoArrowForwardSharp />}
                    iconOnRight
                    className="btn"
                    fontSize="1"
                  >
                    Parla con un esperto
                  </Button>
                </a>
              </LinksColumn>
            </FlexView>
          </ColumnWrapper>
        </RightSide>

        <LeftSide column width="30%">
          <Text fontWeight="900" color="#333333" variant="body">
            Supporto legale 100% online per qualsiasi esigenza
          </Text>
          <Text fontSize="2" color="#333333" variant="body">
            I nostri piani annuali includono sia contratti che consulenze.
            Pensiamo noi a tutti gli aspetti legali della tua attività, così tu
            potrai concentrarti sul tuo business.
          </Text>

          <Spacer variant="small" />
        </LeftSide>
      </FlexView>
    </NavigationAttivita>
  );
}
