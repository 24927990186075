export const CUSTOMER_ARCHIVE = '/web/utente/contratti/';
export const CUSTOMER_CONSULTANCIES = '/web/utente/consulenze/';
export const CUSTOMER_CONSULTANCY = (id: number) => `/prof/consulenze/${id}`;
export const CUSTOMER_CONSULTANCY_ROUTE = `/prof/consulenze/[consultancyId]`;
export const CUSTOMER_OVERVIEW = '/web/utente/panoramica/';
export const CUSTOMER_NEW_ACTIVITIES = '/web/utente/nuova-attivita/';
export const CUSTOMER_ACCOUNT_DATA = '/utente/dati-piano';
export const CUSTOMER_PAYMENTS_AND_INVOICES = '/web/utente/pagamenti-e-fatture';
export const CUSTOMER_ACCOUNTANTS = '/web/utente/servizio-contabilita/';
export const CUSTOMER_REFERRAL = '/utente/guadagna-con-noi';
export const DOCUMENT_WITH_HASH = (
  hash: string,
  documentKindHyphensUrl: string
) => `/modello/${documentKindHyphensUrl}/${hash}`;

export const INTERVIEW_ROUTE =
  '/modello/[documentKind]/[urlHash]/create/questions/[questionId]/';
export const SUBSCRIBE = '/web/abbonati';
export const UPGRADE_PLAN = '/web/upgrade';
export const DOCUMENT_PAY_ROUTE = (
  urlHash: string,
  documentKindHyphensUrl: string
) => `/modello/${documentKindHyphensUrl}/${urlHash}/pay`;
export const QUESTION =
  '/modello/[documentKind]/[urlHash]/create/questions/[questionId]';
export const LANDING_COMPANY_INCORPORATION = '/landing-company-incorporation';
export const LANDING_PARTITA_IVA = '/landing-partita-iva';
export const SUBSCRIBE_WITH_DOCUMENT = '/modello/[documentKind]/[urlHash]/pay';
export const SIGN_UP = '/signup';
export const LOGIN = '/login';
export const NOTARY_AUTOMATION_ROUTE = '/notary-automation-route';
export const AFTER_PAY_COLLECTION_PAGE = 'after-pay-collection-page';
export const VERIFY_EMAIL = 'verify-email';
export const LAWYER_CONSULTANCY = '/utente/consulenze/[consultancyId]';

export const ADMIN_EDITOR = '/admin/admin-editor';
export const ADMIN_DOCUMENTS_LIST = '/admin/documents-list';
export const ADMIN_USER_MANAGEMENT = '/admin/admin-user-management';
export const ADMIN_CONSULTANCIES = '/admin/admin-consultancies';
export const ADMIN_USER_INFO = '/admin/admin-user-info';
export const ADMIN_ACCOUNTANTS = '/admin/admin-accountants';
export const ADMIN_NEW_ACTIVITIES = `/admin/new-activities`;

export const LAWYER_ROOT_OLD = '/prof/consulenze';
export const LAWYER_ROOT = '/web/prof/';
export const LAWYER_ARCHIVE = '/prof/archive';
export const ACCOUNTANT_ROOT = '/web/comm/';
export const ACCOUNTANT_ROOT_USER = (id: number) => `/web/comm/${id}/`;
