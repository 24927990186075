import variables from 'styles/themeVariables';

interface IProps {
  isWhite?: boolean;
  isCustom?: boolean;
  color?: string;
  color2?: string;
}

export default function IconConsulenze(props: IProps) {
  const { isWhite, isCustom, color, color2 } = props;

  return (
    <svg
      width="24px"
      height="17px"
      viewBox="0 0 24 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>consulenze - icon - default</title>
      <g
        id="visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu---default"
          transform="translate(-34.000000, -349.000000)"
          fillRule="nonzero"
        >
          <g id="consulenze" transform="translate(9.000000, 337.500000)">
            <g
              id="consulenze---icon---default"
              transform="translate(25.636364, 11.603500)"
            >
              <circle
                id="Oval"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
                cx="6.375"
                cy="4.875"
                r="3.375"
              ></circle>
              <path
                d="M10.21875,10.125 C8.89875,9.4546875 7.441875,9.1875 6.375,9.1875 C4.2853125,9.1875 0,10.4690625 0,13.03125 L0,15 L7.03125,15 L7.03125,14.2467187 C7.03125,13.3560937 7.40625,12.463125 8.0625,11.71875 C8.58609375,11.124375 9.31921875,10.5726562 10.21875,10.125 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
              <path
                d="M15.1875,9.75 C12.7467187,9.75 7.875,11.2575 7.875,14.25 L7.875,16.5 L22.5,16.5 L22.5,14.25 C22.5,11.2575 17.6282812,9.75 15.1875,9.75 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
              ></path>
              <circle
                id="Oval"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
                cx="15.1875"
                cy="4.125"
                r="4.125"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
