import React, { useEffect, useMemo } from 'react';
import { APIShortUser, APIUserAccountPreview } from 'lexdoit-models';
import useGetAccountPreview from 'queries/useGetAccountPreview';
import useUser from 'queries/useUser';
import { UserArgs } from '@intercom/messenger-js-sdk/types';
import Intercom, {
  update as IntercomUpdate,
  show as IntercomShow,
  shutdown as IntercomShutdown,
} from '@intercom/messenger-js-sdk';
import AppConstants from 'utils/AppConstants';

interface IIntercomClientComponentProps {
  user: APIShortUser | null;
  accountPreview: APIUserAccountPreview | undefined;
}

let __initialized = false;
let hiderCount = 0;

function CustomerClientComponent({
  user,
  accountPreview,
}: IIntercomClientComponentProps) {
  const attributes: UserArgs | undefined = useMemo(() => {
    if (!!user) {
      return {
        name: [user?.name, user?.lastName].filter(Boolean).join(' ').trim(),
        email: user.email,
        user_id: `${user._id!}`,
        user_hash: user.hashedUserInfo,
        phone: user.phoneNumber,
        'User Admin': `${AppConstants.DEPLOY_URL}/web/admin/user/info/?adminUserId=${user._id}`,
        'Legal Plan':
          accountPreview?.subscription?.subscriptionTier ||
          'Nessun Piano Legale',
        'Accounting Plan':
          accountPreview?.accountingSubscription?.subscriptionTier ||
          'Nessun Piano Contabilità',
        'Email Validated': user.emailValidated,
      };
    }
    return undefined;
  }, [user, accountPreview]);

  if (!__initialized) {
    Intercom({
      app_id: AppConstants.intercomId,
      region: 'eu',
    });
    __initialized = true;
  }

  useEffect(() => {
    if (attributes !== undefined) {
      IntercomUpdate(attributes);
    }
  }, [attributes]);

  return null;
}

export default function CustomerSupportChat() {
  const { data: user } = useUser();
  const { data: accountPreview } = useGetAccountPreview();

  if (typeof AppConstants.intercomId !== 'string') {
    return null;
  }

  return (
    <CustomerClientComponent user={user} accountPreview={accountPreview} />
  );
}

export function showCustomerSupportChat() {
  IntercomShow();
}

export function shutDownCustomerSupportChat() {
  IntercomShutdown();
}

export function useHideCustomerSupportChat(hide: boolean = true) {
  useEffect(() => {
    if (hide) {
      hiderCount++;
      IntercomUpdate({
        hide_default_launcher: hiderCount > 0,
      });
      return () => {
        hiderCount--;
        IntercomUpdate({
          hide_default_launcher: hiderCount > 0,
        });
      };
    }
  }, [hide]);
  return null;
}
