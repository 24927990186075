export const dynamicInjectScripts = (script) => {
  const range = document.createRange();
  range.selectNode(document.getElementsByTagName('div').item(0));
  const documentFragment = range.createContextualFragment(script);
  document.body.appendChild(documentFragment);
};

export default {
  dynamicInjectScripts,
};
