import { Spacer, Text } from 'ds';
import React from 'react';
import FlexView from 'react-flexview/lib';
import Link from 'next/link';
import styled from 'styled-components';
import { IoArrowForwardSharp } from 'react-icons/io5';

export const NavigationAttivita = styled(FlexView)`
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 61%,
    rgba(249, 249, 249, 1) 71%
  );

  z-index: 999;
  position: fixed;
  left: 0;
  top: calc(${(props) => props.theme.headerHeight} - 3px);
  width: 100vw;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);

  .content-wrapper {
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;
  }
  a {
    &:hover {
      text-decoration-color: black;
    }
  }

  .help-section {
    margin-top: 20px;
    text-align: center;

    color: rgb(0, 0, 0);
    font-size: 15px;
    a {
      font-weight: bold;
    }
  }

  .btn {
    height: 38px;
  }
`;

export const LinkWrapper = styled(FlexView)`
  justify-content: space-between;
`;

export const LinksColumn = styled(FlexView)`
  & a:not(&:last-child) {
    margin-bottom: 10px;
  }

  a svg {
    margin-left: 5px;
  }

  .btn {
    margin-top: auto;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 45px;
  border-bottom: 2px solid #000000;
`;

export const RightSide = styled(FlexView)`
  padding: 30px 40px 20px 20px;
  height: 100%;
`;
export const LeftSide = styled(FlexView)`
  padding: 30px 20px 20px 40px;
  background-color: #f9f9f9;
  height: 100%;

  .aa-DetachedSearchButton {
    width: 100% !important;
  }
`;

export function NavigationFooter() {
  return (
    <FlexView column>
      <Text fontSize="1" fontWeight="900" variant="body">
        Guide e domande frequenti
      </Text>
      <Spacer variant="small" />
      <LinkWrapper>
        <LinksColumn column>
          <a href="/d/aprire-nuova-attivita/come-aprire-attivita/">
            <Text fontSize="1" color="#2E3132" variant="body">
              Come aprire un’attività
            </Text>
          </a>
          <a href="/d/aprire-nuova-attivita/attivita-commerciale/">
            <Text fontSize="1" color="#2E3132" variant="body">
              Aprire un’attività commerciale
            </Text>
          </a>
        </LinksColumn>
        <LinksColumn column>
          <a href="/d/costituzione-societa-srl-srls/costituire-societa/">
            <Text fontSize="1" color="#2E3132" variant="body">
              Come aprire una società
            </Text>
          </a>

          <a href="/d/aprire-nuova-attivita/aprire-un-negozio/">
            <Text fontSize="1" color="#2E3132" variant="body">
              Aprire un negozio
            </Text>
          </a>
        </LinksColumn>
        <LinksColumn column>
          <a href="/d/aprire-nuova-attivita/come-vendere-online/">
            <Text fontSize="1" color="#2E3132" variant="body">
              Come vendere online
            </Text>
          </a>

          <a href="/d/aprire-nuova-attivita/aprire-un-e-commerce/">
            <Text fontSize="1" color="#2E3132" variant="body">
              Aprire un e-commerce
            </Text>
          </a>
        </LinksColumn>
      </LinkWrapper>

      <LinksColumn column>
        <a href="/d/aprire-nuova-attivita/come-aprire-attivita/">
          <FlexView vAlignContent="center">
            <Text fontSize="1" color="#2E3132" variant="body">
              Vedi tutte le guide <IoArrowForwardSharp />
            </Text>
          </FlexView>
        </a>
      </LinksColumn>
    </FlexView>
  );
}
