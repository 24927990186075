import ReactGA from 'react-ga4';
import AppConstants from './AppConstants';
import { PayContent } from 'components/ProductBasket/products';

// HOW TO MAKE EVENTS
//// tutti devono essere con il trattino _ ( non - ) tra le parole
//// nessun spazio tra le parole, mettere sempre il _
//// tutti devono essere con le parole minuscole

const GA4Analytics = {
  initialize: () => {
    ReactGA.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 2000,
    });
    ReactGA.gtag('set', 'ads_data_redaction', true);
    ReactGA.gtag('set', 'url_passthrough', true);
    ReactGA.initialize(AppConstants.GA4MeasurementId);
  },
  // pass category, action, ?label, ?value as arguments
  track: (category: string, action: string, label?: string, value?: number) =>
    ReactGA.event({
      category,
      action,
      label,
      value,
    }),
  trackPage: (pathname: string) =>
    ReactGA.send({ hitType: 'pageview', page: pathname }),
  trackVirtualPageView: () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  },
  onCookieConsent: () => {
    ReactGA.set({ allowAdFeatures: true });
  },
  trackUserSubscribed: ({ subscriptionTier }) => {
    ReactGA.event({
      category: 'user',
      action: 'subscribed',
      label: 'user_subscribed',
      value: subscriptionTier,
    });
  },
  trackAbTestPageView: ({
    experimentId,
    variantId,
  }: {
    experimentId: string;
    variantId: string;
  }) => {
    ReactGA.set({ exp: `${experimentId}.${variantId}` });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  },
  setLeadIdDimension: (userId: number) => {
    if (!userId) {
      return;
    }
    ReactGA.set({
      dimension2: userId.toString(),
    });
  },
  trackQuizStartedByKind: (payContent: PayContent) => {
    ReactGA.event({
      category: 'quiz_nuove_attivita',
      action: 'quiz_started',
      label: `quiz_started_${payContent || 'na_generica'}`,
    });
  },
  trackQuizCompleted: (result: string) => {
    ReactGA.event({
      category: 'quiz_nuove_attivita',
      action: 'quiz_completed',
      label: `quiz_completed_${result}`,
    });
  },
  trackSubscriptionWithPayContent: ({
    id,
    price,
    subscriptionTier = '',
    payContent,
  }) => {
    const eventPrefix = (() => {
      switch (payContent) {
        case 'piva': {
          return 'partita_iva';
        }
        case 'attivita': {
          return 'attivita';
        }

        case 'srl': {
          return 'srl';
        }
        case 'associazione': {
          return 'associazione';
        }

        case 'startup': {
          return 'startup';
        }

        case 'srls': {
          return 'srls';
        }

        default: {
          return '';
        }
      }
    })();

    const titleCaseSubscriptionTier =
      subscriptionTier.charAt(0).toUpperCase() + subscriptionTier.substring(1);

    ReactGA.event('addTransaction', {
      item_id: id,
      price,
    });

    ReactGA.event('addItem', {
      item_id: id,
      item_name: `${eventPrefix}_${titleCaseSubscriptionTier}`,
      item_category: `${eventPrefix}`,
      price,
      quantity: '1',
    });

    ReactGA.event('send');
  },
  trackSubscriptionPurchase: ({ id, subscriptionTier, price }) => {
    ReactGA.event('addTransaction', {
      item_id: id,
      price,
    });

    ReactGA.event('addItem', {
      item_id: id,
      item_name: subscriptionTier,
      item_category: 'subscription',
      price,
      quantity: '1',
    });

    ReactGA.event('send');
  },
  trackDocumentPurchase: ({ id, name, price }) => {
    ReactGA.event('addTransaction', {
      item_id: id,
      price,
    });

    ReactGA.event('addItem', {
      item_id: id,
      item_name: name,
      item_category: 'single_document',
      price,
      quantity: '1',
    });

    ReactGA.event('send');
  },
  trackPaymentShown: (paymentType) =>
    ReactGA.event({
      category: 'payment',
      action: 'shown',
      label: paymentType,
    }),

  trackPaymentClick: (paymentType) =>
    ReactGA.event({
      category: 'payment',
      action: 'click',
      label: paymentType,
    }),

  trackPaymentSuccess: (paymentType) =>
    ReactGA.event({
      category: 'payment',
      action: 'success',
      label: paymentType,
    }),

  trackGenericSignUp: () => {
    ReactGA.event('signup_generico', {
      signup_value: 3.5,
    });
  },
  trackGenericSub: () => {
    ReactGA.event('sub_generica', {
      signup_value: 116,
    });
  },
  docFlowSignUp: () => {
    ReactGA.event('signup_doc', {
      signup_value: 3.5,
    });
  },
  docPay: () => {
    ReactGA.event('sub_doc', {
      signup_value: 116,
    });
  },
  payContentAssoPay: () => {
    ReactGA.event('sub_associazione', {
      signup_value: 142,
    });
  },
  payContentCompanyPay: () => {
    ReactGA.event('sub_societa', {
      signup_value: 370,
    });
  },
};

export default GA4Analytics;
