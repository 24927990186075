import variables from 'styles/themeVariables';

interface IProps {
  isWhite?: boolean;
  isCustom?: boolean;
  color?: string;
  color2?: string;
}

export default function IconContabilità(props: IProps) {
  const { isWhite, isCustom, color, color2 } = props;

  return (
    <svg
      width="16px"
      height="19px"
      viewBox="0 0 16 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>contabilita - icon - default</title>
      <g
        id="visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu---default"
          transform="translate(-38.000000, -424.000000)"
          fillRule="nonzero"
        >
          <g id="contabilita" transform="translate(9.000000, 414.000000)">
            <g
              id="contabilita---icon---default"
              transform="translate(29.267747, 10.197250)"
            >
              <path
                d="M2.07812501,17.8125001 L0.890625004,17.8125001 C0.398746396,17.8125001 0,17.4137537 0,16.9218751 L0,11.578125 C0,11.0862464 0.398746396,10.6875 0.890625004,10.6875 L2.07812501,10.6875 C2.57000362,10.6875 2.96875001,11.0862464 2.96875001,11.578125 L2.96875001,16.9218751 C2.96875001,17.4137537 2.57000362,17.8125001 2.07812501,17.8125001 L2.07812501,17.8125001 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
              <path
                d="M10.390625,17.8125001 L9.20312504,17.8125001 C8.71124643,17.8125001 8.31250003,17.4137537 8.31250003,16.9218751 L8.31250003,8.01562503 C8.31250003,7.52374643 8.71124643,7.12500003 9.20312504,7.12500003 L10.390625,7.12500003 C10.8825037,7.12500003 11.28125,7.52374643 11.28125,8.01562503 L11.28125,16.9218751 C11.28125,17.4137537 10.8825037,17.8125001 10.390625,17.8125001 L10.390625,17.8125001 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
              <path
                d="M14.5468751,17.8125001 L13.3593751,17.8125001 C12.8674964,17.8125001 12.4687501,17.4137537 12.4687501,16.9218751 L12.4687501,3.85937502 C12.4687501,3.36749641 12.8674964,2.96875001 13.3593751,2.96875001 L14.5468751,2.96875001 C15.0387537,2.96875001 15.4375001,3.36749641 15.4375001,3.85937502 L15.4375001,16.9218751 C15.4375001,17.4137537 15.0387537,17.8125001 14.5468751,17.8125001 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
              ></path>
              <path
                d="M6.23437503,17.8125001 L5.04687502,17.8125001 C4.55499641,17.8125001 4.15625002,17.4137537 4.15625002,16.9218751 L4.15625002,0.890625004 C4.15625002,0.398746396 4.55499641,0 5.04687502,0 L6.23437503,0 C6.72625363,0 7.12500003,0.398746396 7.12500003,0.890625004 L7.12500003,16.9218751 C7.12500003,17.4137537 6.72625363,17.8125001 6.23437503,17.8125001 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
