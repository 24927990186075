import { Modal } from 'buildo-react-components/lib';
import { ModalContext } from 'context/ModalContext';
import useUser from 'queries/useUser';
import { useContext } from 'react';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';
import { mobileMixin } from 'styles/themeResponsiveMixin';
import { FaClock } from 'react-icons/fa';
import FlexView from 'react-flexview/lib';

const LivestormHelperEl = styled(Modal)`
  .modal-header {
    border-color: transparent !important;
  }

  .modal-content {
    min-width: 582px !important;

    ${mobileMixin`
      min-width: unset !important;
    `}

    .modal-body {
      padding: 0 !important;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        font-weight: bold;

        ${mobileMixin`
          top: 10px;
          right: 10px;
        `}
      }

      .livestorm-left-side {
        padding: 90px 30px;
        width: 330px;
        color: #1a1a1a;

        ${mobileMixin`
          padding: 10px 30px 5px 10px;
        `}

        &-title {
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;

          ${mobileMixin`
            font-size: 19px;
            line-height: 22px;
          `}
        }

        &-timing {
          margin: 20px 0;
          color: #1a1a1a99;

          ${mobileMixin`
            display: none !important;
          `}

          &-text {
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
          }
        }

        &-subtitle {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          ${mobileMixin`
            display: none;
          `}
        }
      }

      iframe {
        overflow: hidden;
        width: 470px;
        height: 582px;

        ${mobileMixin`
          overflow: unset;
          width: 100%;
          height: 582px;
        `}
      }
    }
  }
`;

const FlexContainerEl = styled(FlexView)`
  position: relative;

  ${mobileMixin`
    flex-direction: column !important;
  `}
`;

interface ILivestormHelperProps {
  onDismiss?: () => void;
  isOpen?: boolean;
}

export default function LivestormHelper(props: ILivestormHelperProps) {
  const modalContext = useContext(ModalContext);
  const { data: user } = useUser();

  if (!modalContext.isLivestormModalOpen) {
    return null;
  }

  return (
    <LivestormHelperEl
      transitionEnterTimeout={0}
      transitionLeaveTimeout={0}
      dismissOnClickOutside={false}
      title=""
      onDismiss={() => {
        modalContext.setIsLivestormModalOpen(false);
        modalContext.setLivestormQuizKind('');
        props.onDismiss && props.onDismiss();
      }}
    >
      <FlexContainerEl hAlignContent="center">
        <IoClose
          className="close-icon"
          fontSize="24px"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            modalContext.setIsLivestormModalOpen(false);
            modalContext.setLivestormQuizKind('');
            props.onDismiss && props.onDismiss();
          }}
        />
        <div className="livestorm-left-side">
          <div className="livestorm-left-side-title">
            Scegli quando parlare gratis con un esperto
          </div>
          <FlexView className="livestorm-left-side-timing">
            <FaClock color="#1A1A1A99" size="20" />
            <div className="livestorm-left-side-timing-text">20 min</div>
          </FlexView>
          <div className="livestorm-left-side-subtitle">
            Partecipa ai nostri eventi online gratuiti. Un esperto LexDo.it ti
            spiegherà come aprire la tua{' '}
            {modalContext.livestormQuizKind === 'Company' && 'società'}
            {modalContext.livestormQuizKind === 'Association' &&
              'associazione'}{' '}
            e potrà rispondere a tutte le tue domande.
          </div>
        </div>

        {modalContext.livestormQuizKind === 'Company' && (
          <iframe
            frameBorder="0"
            src={`https://app.livestorm.co/lexdoit/costituzione-societa-srl-srls?type=light&email=${
              user?.email || ''
            }`}
            title="Parla con un Esperto - Apri la tua Società | LexDo.it SRL"
          ></iframe>
        )}

        {modalContext.livestormQuizKind === 'Association' && (
          <iframe
            frameBorder="0"
            src={`https://app.livestorm.co/lexdoit/evento-online-gratuito-come-aprire-associazione-con-lexdoit?type=light&email=${
              user?.email || ''
            }`}
            title="Parla con un Esperto - Apri la tua Associazione | LexDo.it SRL"
          ></iframe>
        )}
      </FlexContainerEl>
    </LivestormHelperEl>
  );
}
