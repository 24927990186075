import variables from 'styles/themeVariables';

interface IProps {
  isWhite?: boolean;
  isCustom?: boolean;
  color?: string;
  color2?: string;
}

export default function IconNuoveAttività(props: IProps) {
  const { isWhite, isCustom, color, color2 } = props;

  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>nuove attivita - icon - default</title>
      <g
        id="visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu---default"
          transform="translate(-36.000000, -185.000000)"
          fillRule="nonzero"
        >
          <g id="nuoveattivita" transform="translate(9.000000, 174.000000)">
            <g
              id="nuove-attivita---icon---default"
              transform="translate(27.909354, 11.558142)"
            >
              <path
                d="M11.9264076,5.01381618 C11.414823,5.01409881 10.9746736,5.37570949 10.8751234,5.87751486 C10.7755732,6.37932023 11.0443466,6.88156987 11.5170808,7.07712357 C11.989815,7.27267728 12.5348558,7.10707428 12.8188949,6.6815856 C13.1029341,6.25609692 13.0468681,5.68921914 12.6849828,5.32761444 C12.4839799,5.1262033 12.2109569,5.01326316 11.9264076,5.01381618 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
              <path
                d="M17.8965716,0.725537702 C17.8963834,0.724339683 17.8963834,0.723119631 17.8965716,0.721921612 C17.8272663,0.418112954 17.5916821,0.179881745 17.2886667,0.107186374 C16.0913393,-0.184913311 14.2077584,0.126472185 12.1204711,0.962190679 C10.0167105,1.80594493 8.04553984,3.05148691 6.7132117,4.38622577 C6.28616796,4.81100154 5.88943415,5.26519797 5.52592894,5.74547369 C4.62873692,5.70529492 3.83842044,5.83306342 3.17265816,6.12355595 C0.852735769,7.14449859 0.190187791,9.75732425 0.013802974,10.8300975 C-0.0379327301,11.1390661 0.0574447964,11.4544819 0.271713635,11.6830144 C0.485982474,11.9115468 0.794606139,12.0270259 1.10626383,11.9952819 L1.11148707,11.9952819 L3.6957858,11.713227 C3.6990001,11.7461735 3.70261619,11.7763076 3.7054287,11.804031 C3.7386203,12.1189381 3.87905766,12.4128635 4.10319856,12.6365352 L5.36441027,13.8985505 C5.58774634,14.1230002 5.88161441,14.2636198 6.19651267,14.2967221 L6.28289704,14.3059632 L6.00164562,16.8870476 C6.00164562,16.8870476 6.00164562,16.8902619 6.00164562,16.8922709 C5.97258145,17.1733519 6.06325767,17.4536743 6.25143434,17.6644842 C6.439611,17.875294 6.70788058,17.9970901 6.99044524,18 C7.04630719,18 7.10207741,17.9954754 7.15718715,17.9863389 C8.23598723,17.8127666 10.8476075,17.1582544 11.8657376,14.8258765 C12.1538195,14.1645339 12.2811862,13.3774318 12.2454271,12.4818469 C12.7278646,12.1191416 13.1837194,11.7223667 13.6094964,11.2945641 C14.9526728,9.95821811 16.2022327,8.00311899 17.0371476,5.92989426 C17.8684464,3.86631244 18.1814391,1.96866896 17.8965716,0.725537702 Z M13.5950321,7.75200165 C12.9198969,8.42784096 11.9040638,8.63031557 11.0214018,8.26497448 C10.1387399,7.89963338 9.56315963,7.03845963 9.56315963,6.08317628 C9.56315963,5.12789294 10.1387399,4.26671918 11.0214018,3.90137809 C11.9040638,3.536037 12.9198969,3.7385116 13.5950321,4.41435092 C14.0420084,4.85461523 14.293689,5.45578447 14.293689,6.08317628 C14.293689,6.7105681 14.0420084,7.31173733 13.5950321,7.75200165 Z"
                id="Shape"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
              ></path>
              <path
                d="M5.21976668,14.2408736 C5.06429434,14.2215848 4.90712916,14.259733 4.77780017,14.3481509 C4.52105781,14.5237322 4.26311008,14.6973045 4.00275163,14.8664571 C3.47600791,15.2087803 2.84600473,14.6077058 3.15899738,14.0628816 L3.64716948,13.2191274 C3.78972189,13.010595 3.79693904,12.7379054 3.66561673,12.5221245 C3.53429442,12.3063435 3.28877408,12.1874677 3.03805927,12.2182741 C2.49624856,12.2867922 1.99262094,12.5335881 1.60648956,12.9197955 C1.45943525,13.0672516 1.0122455,13.5148432 0.771574646,15.2204321 C0.703070141,15.7103372 0.659887525,16.2034505 0.642198995,16.6978056 C0.637755864,16.8711388 0.703519019,17.0389171 0.824563658,17.1630635 C0.945608298,17.2872099 1.11166926,17.3571962 1.28505937,17.3571393 L1.30113088,17.3571393 C1.79586653,17.3396217 2.28937611,17.2967078 2.77970976,17.2285672 C4.48610228,16.9874946 4.93369382,16.539903 5.08074813,16.3928487 C5.46874938,16.0065508 5.71494943,15.5005595 5.779457,14.9568594 C5.82247125,14.6046316 5.57196054,14.2841649 5.21976668,14.2408736 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
