import React, { useContext, useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import FlexView from 'react-flexview';
import Logo from '../Logo';

import LoginLink from '../LoginLink';
import AccountAreaHeaderNavigation from './AccountAreaHeaderNavigation';
import ExpandableMenuMobileSection from './ExpandableMenuMobileSection';

import styled, { css } from 'styled-components';

import {
  mobileMixin,
  tabletMixin,
  desktopMixin,
  mobileAndTabletMixin,
} from '../../styles/themeResponsiveMixin';

import dynamic from 'next/dynamic';

import {
  IoArrowForward,
  IoArrowForwardCircleOutline,
  IoCardOutline,
  IoChatbubblesOutline,
  IoChevronDown,
  IoChevronUp,
  IoHeartOutline,
  IoMenuOutline,
  IoPricetagOutline,
} from 'react-icons/io5';

import NavigationSubMenu from './NavigationSubMenu';

import { Text } from 'ds';
import { NextRouter, useRouter } from 'next/router';

import CalendlyHelper from '../CalendlyHelper/CalendlyHelper';
import {
  AFTER_PAY_COLLECTION_PAGE,
  CUSTOMER_ACCOUNTANTS,
  CUSTOMER_ACCOUNT_DATA,
  CUSTOMER_ARCHIVE,
  CUSTOMER_CONSULTANCIES,
  CUSTOMER_CONSULTANCY_ROUTE,
  CUSTOMER_NEW_ACTIVITIES,
  CUSTOMER_OVERVIEW,
  CUSTOMER_PAYMENTS_AND_INVOICES,
  CUSTOMER_REFERRAL,
  LAWYER_ARCHIVE,
  LAWYER_ROOT,
  NOTARY_AUTOMATION_ROUTE,
  QUESTION,
  SUBSCRIBE,
  SUBSCRIBE_WITH_DOCUMENT,
  UPGRADE_PLAN,
  VERIFY_EMAIL,
} from 'constants/routes';
import DocumentSaveForLater from 'components/DocumentSaveForLater/DocumentSaveForLater';
import useDocumentKind from 'queries/useDocumentKind';
import useUser from 'queries/useUser';
import { ModalContext, ModalContextType } from 'context/ModalContext';
import AppConstants from 'utils/AppConstants';
import { FaPhoneAlt, FaUserCircle } from 'react-icons/fa';
import LivestormHelper from 'components/LivestormHelper/LivestormHelper';
import { useDoLogout } from 'mutations/useDoLogout';
import IconPanoramica from 'components/AccountHandler/icons/IconPanoramica';
import IconNuoveAttività from 'components/AccountHandler/icons/IconNuoveAttività';
import IconContratti from 'components/AccountHandler/icons/IconContratti';
import IconConsulenze from 'components/AccountHandler/icons/IconConsulenze';
import IconContabilità from 'components/AccountHandler/icons/IconContabilità';
import variables from 'styles/themeVariables';
import { BsFillChatFill } from 'react-icons/bs';
import { showCustomerSupportChat } from 'components/CustomerClientComponent/CustomerSupportChat';
import useDocumentByHash from 'queries/useDocumentByHash';

const Popover = dynamic(() => import('buildo-react-components/lib/Popover'));
const Button = dynamic(() => import('ds').then((mod) => mod.Button));

const nuoveAttivitaMenuItems = [
  {
    to: '/costituzione-societa-srl-srls/',
    label: 'Apertura Società',
  },
  {
    to: '/aprire-partita-iva-online/',
    label: 'Aprire Partita IVA',
  },
  {
    to: '/costituire-associazione-online/',
    label: 'Costituzione Associazione',
  },
];
const productsMenuItem = [
  {
    to: '/modello/contratto-di-appalto-prestazione-opera/',
    label: "Realizzazione d'opera",
  },
  {
    to: '/modello/contratto-fornitura-servizio/',
    label: 'Fornitura di servizio',
  },
  {
    to: '/modello/accordo-di-riservatezza-non-disclosure-agreement/',
    label: 'Accordo di riservatezza',
  },
  {
    to: '/modello/termini-condizioni-sito-web-app/',
    label: 'Termini e condizioni sito',
  },
  {
    to: '/modello/privacy-policy/',
    label: 'Privacy policy',
  },
];
const consultancyProductsMenuItem = [
  {
    to: '/consulenza-legale-online/',
    label: 'Consulenza legale',
  },
  {
    to: '/consulenza-legale-online/',
    label: 'Revisione di contratti',
  },
  {
    to: '/consulenze/registrazione-marchio/',
    label: 'Registrazione marchio',
  },
  {
    to: '/consulenze/consulenza-GDPR-privacy-dati-personali/',
    label: 'Adeguamento GDPR',
  },
  {
    to: '/consulenze/work-for-equity-startup-innovative-srl-spa/',
    label: 'Work for equity',
  },
];

interface IHeaderStyledProps {
  $headerFader: boolean;
  $showHeader: boolean;
}

const HeaderStyled = styled(FlexView)<IHeaderStyledProps>`
  position: fixed;
  top: ${(props) => (props.$headerFader ? '-78px' : '0px')};
  ${(props) => (props.$headerFader ? 'transition: top 325ms ease-in;' : '')};

  right: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  height: ${(props) => props.theme.headerHeight};
  ${(props) => {
    if (props.$showHeader) {
      return css`
        top: 0px;
      `;
    }
  }}
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.white} !important;

  .header-menu a:hover {
    text-decoration: none;
  }

  &.header-menu-open {
    z-index: 2147483647;
  }

  padding: 0 10px;

  &-interview {
    .logo {
      ${mobileMixin`
        .lex,
        .do-it {
          display: none !important;
        }
      `}
    }
  }

  ${tabletMixin`
     .wrapper {
      .desktop-menu .item {
        padding: 10px 6px !important;
      }

      .menu-mobile {
        display: none !important;
      }
    }
  `}

  ${desktopMixin`
    .wrapper {
      .menu-mobile {
        display: none !important;
      }
    }
  `}

  ${mobileMixin`
   .wrapper {
      .desktop-menu {
        display: none !important;
      }
    }
  `}

  .interview-wrapper {
    .interview-title {
      margin-left: auto;
      padding: 0 35px;

      ${mobileMixin`
        margin-right: auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      `}

      span {
        font-size: 18px;
        font-weight: $bold;
      }
    }

    .book-phone-call-label {
      margin-right: 10px;
    }

    .right-side {
      margin-left: auto;
      .open-chat-button:hover {
        cursor: pointer;
      }

      ${mobileAndTabletMixin`
        display: none !important;
      `}
    }
  }

  .landing-wrapper {
    .landing-wrapper-label {
      margin-right: 10px;
    }

    .right-side {
      margin-left: auto;

      ${mobileMixin`
          .button .button-inner.is-small {
            padding: 0 !important;
          }
        `}
    }
  }

  .react-popover.is-open .popover-content {
    top: 76px !important;
    ${mobileMixin`
      background: transparent;
      &:after {
        border-bottom-color: transparent;
      }
    `}
  }
`;

const WrapperStyled = styled(FlexView)`
  width: 100%;
  max-width: 1170px;
`;

const DesktopMenuStyled = styled(FlexView)`
  position: relative;
  z-index: 101;
  top: auto;
  right: auto;
  overflow: visible;
  width: auto;
  padding: 0;
  box-shadow: none !important;
  margin-left: 6px;

  .popover-content {
    z-index: 9999;
  }

  .last-document-button-box {
    margin-right: 14px;
  }

  .item {
    font-family: ${(props) => props.theme.fontLato};
    font-size: 16px;
    line-height: 54px;
    position: relative;
    height: 77px;
    padding: 10px 14px;
    cursor: pointer;
    text-decoration: none;

    &.with-icon-left {
      line-height: unset !important;
      svg {
        margin-right: 8px;
      }
    }

    &.with-icon {
      svg {
        margin-left: 4px;
      }
    }

    &.second-level {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 77px;
    }

    &.selected,
    &.selected:hover {
      background-color: white !important;
      color: rgba(0, 0, 0, 0.8) !important;
      font-weight: 900;
    }

    &:not(.login-link) {
      color: rgba(0, 0, 0, 0.8);

      &:hover {
        background: rgba(0, 0, 0, 0.03);
      }
    }
  }
`;

const MenuMobileStyled = styled(FlexView)`
  width: 100%;

  .call {
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: black;
  }

  a {
    color: unset !important;
    &:hover {
      text-decoration-color: black;
    }
  }

  .popover-content {
    width: 100%;

    .header-menu {
      height: calc(100% - ${(props) => props.theme.headerHeight});
      overflow-y: scroll;

      padding: 0 10px 200px 10px;

      position: fixed;
      top: ${(props) => props.theme.headerHeight};
      left: 0;
      width: 100%;
      background: ${(props) => props.theme.white};
      overflow: auto;

      .item {
        padding: 10px 0;
        width: 100%;
        font-size: 18px;
        color: ${(props) => props.theme.black} !important;

        ${mobileMixin`
          text-align: left;
        `}
      }

      .last-document-button {
        width: 100%;
        padding: 15px 0;

        .resume-document.button {
          margin: auto;
        }
      }

      .search-wrapper,
      .autocomplete-wrapper {
        width: 100%;
      }
      .search-wrapper {
        padding: 5px;
      }

      .separator-mobile {
        height: 1px;
        width: 34px;
        border-bottom: 1px solid #000000;
        margin: 10px 0;
      }
    }
  }

  .btn {
    height: 38px;
  }
`;

const MobileButtonWithDescription = styled(FlexView)`
  width: 99%;

  .button-wrapper {
    margin-top: 10px;
    background: #eeeeee;
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;

    ${mobileAndTabletMixin`
      margin-top: 0;
    `}
  }

  ${mobileMixin`
    img {
      display: none;
    }
  `}
  img {
    margin-right: 15px;
  }

  .icon {
    margin-right: 10px;
  }

  .arrow {
    margin-left: auto;
  }

  .green-bubble {
    width: 9px;
    height: 9px;
    background-color: #4ab062;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const UserAreaMenuDesktop = styled(FlexView)`
  cursor: pointer;

  ${mobileAndTabletMixin`
    display: none !important;
  `}

  .settings-arrow {
    margin-left: 3px;
  }

  .settings-drop-menu {
    position: absolute;
    top: 72px;
    right: 0;

    .drop-menu-item {
      cursor: pointer;
      background-color: #fbfbfb;
      padding: 10px 20px 10px 20px;
      border: 1px solid #dddada;
      border-bottom: 1px solid #e7e7e7;
      width: 260px;

      svg {
        margin-left: 10px;
      }

      &.last {
        border-radius: 0 0 0 11px;
      }

      &.activeRoute,
      &:hover {
        background-color: #f0f0f0;
        font-weight: ${(props) => props.theme.bold};
      }
    }
  }
`;

const UserAreaMenuMobile = styled.div`
  ${desktopMixin`
    display: none !important;
  `}

  .mobile-menu-wrapper {
    position: absolute;
    top: 72px;
    right: 0;
    left: 0;
    bottom: 0;
    height: calc(100vh - 72px);
    width: 100vw;
    background-color: white;
    padding-top: 20px;
    justify-content: space-between;
  }

  .menu-items-wrapper-top {
    .menu-item-top {
      padding: 0 20px;

      &-icon-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 9px;
      }

      &.activeRoute {
        p {
          font-weight: ${(props) => props.theme.bold};
        }
      }

      + .menu-item-top {
        margin-top: 14px;
      }
    }
  }

  .menu-items-wrapper-bottom {
    padding-bottom: 80px;
    background-color: ${(props) => props.theme.mobileMenuBackgroundGrey};

    .menu-item-bottom {
      padding: 5px 20px;
      width: 100%;
      border-top: 1px solid #e7e7e7;

      &.activeRoute {
        background-color: #f0f0f0;

        p {
          font-weight: ${(props) => props.theme.bold};
        }
      }

      &-icon-wrapper {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
`;

export type MenuItems = Array<{ label: string; to: string }>;

interface IProps {
  id?: string;
  className?: string;
  style?: any;
  logoVariant?: string;
  logoStyle?: any;
  logoImage?: string;
  documentKindTitle?: string;
  menuItems: MenuItems;
  partnerCode?: string;
  isLandingPartitaIva?: boolean;
  isLandingCostituzione?: boolean;
  isLandingAprireAttivita?: boolean;
  isLandingAssociazione?: boolean;
  isLandingStartup?: boolean;
  isLandingSRLS?: boolean;
  deltaOpacity?: any;
  showHeader?: boolean;
  headerFader?: boolean;
  isInterview?: boolean;
  hideSignupLink?: boolean;
  isLanding?: boolean;
  router: NextRouter;
  optionalDocumentKind?: any;
  user: any;
  modalContext: ModalContextType;
  doLogout: any;
  menuItemsRef: any;
}

export default function Header(props: IProps) {
  const {
    id,
    className,
    style,
    menuItems,
    logoImage,
    logoStyle,
    logoVariant,
    isInterview,
    hideSignupLink: _hideSignupLink = false,
    isLanding,
    showHeader,
    headerFader,
  } = props;

  const router = useRouter();
  const modalContext = useContext(ModalContext);
  const { data: user } = useUser();
  const { documentKind: documentKindUrl } = router.query;
  const { data: documentByHash } = useDocumentByHash(
    router.query.urlHash as string
  );
  const { data: optionalDocumentKind } = useDocumentKind(
    documentKindUrl as string
  );
  const doLogout = useDoLogout();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [
    isMobileLandingNavigationExpanded,
    setIsMobileLandingNavigationExpanded,
  ] = useState(false);
  const [isMobileProductsSubMenuExpanded, setIsMobileProductsSubMenuExpanded] =
    useState(false);
  const [menuProductsOpen, setMenuProductsOpen] = useState(false);
  const [menuServicesOpen, setMenuServicesOpen] = useState(false);
  const [menuUserAreaDesktopOpen, setMenuUserAreaDesktopOpen] = useState(false);
  const [menuUserAreaMobileOpen, setMenuUserAreaMobileOpen] = useState(false);
  const menuUserAreaDesktopRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuUserAreaDesktopRef.current &&
        !menuUserAreaDesktopRef.current.contains(event.target)
      ) {
        setMenuUserAreaDesktopOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuUserAreaDesktopRef]);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeMenu);

    return () => router.events.off('routeChangeComplete', closeMenu);
  }, []);

  const menuUserAreaMobileToggle = () =>
    setMenuUserAreaMobileOpen(!menuUserAreaMobileOpen);
  const menuUserSettingsToggle = () =>
    setMenuUserAreaDesktopOpen(!menuUserAreaDesktopOpen);
  const onMenuIconClick = () => setMenuIsOpen(!menuIsOpen);
  const onMenuMobileClick = () => setMenuMobileOpen(!menuMobileOpen);
  const onMenuProductsClick = () => {
    setMenuProductsOpen(!menuProductsOpen);
    setMenuServicesOpen(false);
  };
  const onMenuServicesClick = () => {
    setMenuServicesOpen(!menuServicesOpen);
    setMenuProductsOpen(false);
  };
  const onMobileLandingNavigationToggle = () =>
    setIsMobileLandingNavigationExpanded(!isMobileLandingNavigationExpanded);
  const onMobileProductsSubMenuToggle = () =>
    setIsMobileProductsSubMenuExpanded(!isMobileProductsSubMenuExpanded);

  const closeMenu = () => {
    setMenuIsOpen(false);
    setMenuProductsOpen(false);
    setMenuServicesOpen(false);
    setMenuMobileOpen(false);
  };

  const routerPathnames = [
    SUBSCRIBE,
    QUESTION,
    UPGRADE_PLAN,
    SUBSCRIBE_WITH_DOCUMENT,
    NOTARY_AUTOMATION_ROUTE,
    AFTER_PAY_COLLECTION_PAGE,
    VERIFY_EMAIL,
    CUSTOMER_OVERVIEW,
    CUSTOMER_NEW_ACTIVITIES,
    CUSTOMER_ARCHIVE,
    CUSTOMER_CONSULTANCIES,
    CUSTOMER_ACCOUNTANTS,
    CUSTOMER_PAYMENTS_AND_INVOICES,
    CUSTOMER_ACCOUNT_DATA,
    CUSTOMER_REFERRAL,
    LAWYER_ROOT,
    LAWYER_ARCHIVE,
  ];

  const showMenu = !routerPathnames.includes(router.pathname);

  const headerProps = {
    id,
    className: cx('header', className, {
      'header-interview': isInterview,
      'header-menu-open': menuIsOpen,
    }),
    style,
  };

  const logoProps = {
    variant: logoVariant || 'Black',
    image: true,
    style: { fontSize: 32 },
    logoImage,
    logoStyle,
  } as any;

  const hideSignupLink = _hideSignupLink || isLanding;

  const templateMenuItem =
    (onMenuIconClick = () => undefined) =>
    // eslint-disable-next-line react/display-name
    ({ label, to, internal }) =>
      internal ? (
        <a href={to} key={`${to}-${label}`} style={{ display: 'flex' }}>
          <span
            data-test-id={`header-menu-${label.toLowerCase()}`}
            onClick={onMenuIconClick}
            className="item"
            key={to}
          >
            {label}
          </span>
        </a>
      ) : (
        <a
          href={to}
          key={`${to}-${label}`}
          data-test-id={`header-menu-${label.toLowerCase()}`}
          onClick={onMenuIconClick}
          className="item"
        >
          {label}
        </a>
      );

  const templateMenu = ({ menuItems, isLanding, hideSignupLink }) => {
    return (
      <DesktopMenuStyled
        className="desktop-menu"
        grow
        hAlignContent="right"
        vAlignContent="center"
      >
        <FlexView className="last-document-button-box" />
        <Popover
          popover={{
            anchor: isLanding ? 'end' : 'start',
            position: 'bottom',
            isOpen: menuServicesOpen,
            onToggle: onMenuServicesClick,
            dismissOnScroll: false,
            dismissOnClickOutside: true,
            event: 'click',
            content: <NavigationSubMenu.NavigationSubMenuServices />,
          }}
        >
          <a
            className={`item second-level with-icon ${
              menuServicesOpen ? 'selected' : ''
            }`}
          >
            Apri attività
            {menuServicesOpen ? <IoChevronUp /> : <IoChevronDown />}
          </a>
        </Popover>
        <Popover
          popover={{
            anchor: isLanding ? 'end' : 'start',
            position: 'bottom',
            isOpen: menuProductsOpen,
            onToggle: onMenuProductsClick,
            dismissOnClickOutside: true,
            dismissOnScroll: false,
            event: 'click',
            content: <NavigationSubMenu.NavigationSubMenuProducts />,
          }}
        >
          <a
            className={`item second-level with-icon ${
              menuProductsOpen ? 'selected' : ''
            }`}
          >
            Contratti e consulenze{' '}
            {menuProductsOpen ? <IoChevronUp /> : <IoChevronDown />}
          </a>
        </Popover>

        <FlexView className="header-menu" shrink={false}>
          {menuItems.map(templateMenuItem())}
          <LoginLink hideSignupLink={hideSignupLink} />
        </FlexView>
      </DesktopMenuStyled>
    );
  };

  const onWheel = (e: any) => {
    e.stopPropagation();
  };

  const templateMenuMobile = ({ menuItems, onMenuIconClick }) => {
    const content = (
      <FlexView className="header-menu" column onWheel={onWheel}>
        <ExpandableMenuMobileSection
          title="Apri attività"
          onToggle={onMobileLandingNavigationToggle}
          isExpanded={isMobileLandingNavigationExpanded}
        >
          <FlexView column shrink={false}>
            <a href="/aprire-nuova-attivita/">
              <MobileButtonWithDescription vAlignContent="center" column>
                <Text
                  fontSize="3"
                  color="#858585"
                  textAlign="center"
                  fontWeight="bold"
                  display={['none', 'none', 'unset']}
                  variant="body"
                >
                  Hai bisogno di aiuto?
                </Text>
                <FlexView vAlignContent="center" className="button-wrapper">
                  <IoChatbubblesOutline className="icon" fontSize="24" />
                  <FlexView column>
                    <Text
                      fontWeight="bold"
                      lineHeight="19px"
                      fontSize="2"
                      variant="body"
                    >
                      Apri la tua attività
                    </Text>
                    <FlexView vAlignContent="center">
                      <Text fontSize="2" color="#2E3132" variant="body">
                        Prova il quiz gratis
                      </Text>
                    </FlexView>
                  </FlexView>
                  <IoArrowForward className="arrow" fontSize="24" />
                </FlexView>
              </MobileButtonWithDescription>
            </a>

            {nuoveAttivitaMenuItems.map(templateMenuItem(onMenuIconClick))}

            <div className="separator-mobile"></div>

            {templateMenuItem(onMenuIconClick)({
              label: 'Guide e domande frequenti',
              to: '/d/aprire-nuova-attivita/come-aprire-attivita/',
              internal: true,
            })}
          </FlexView>
        </ExpandableMenuMobileSection>

        <ExpandableMenuMobileSection
          title="Contratti e consulenze"
          onToggle={onMobileProductsSubMenuToggle}
          isExpanded={isMobileProductsSubMenuExpanded}
        >
          <FlexView column shrink={false}>
            <Text fontWeight="900" fontSize="2" variant="body">
              Contratti su misura online
            </Text>
            {productsMenuItem.map(templateMenuItem(onMenuIconClick))}
            <a href="/prodotti/">
              <Button
                variant="secondary"
                icon={<IoArrowForward />}
                iconOnRight
                fontSize="1"
                className="btn"
              >
                Vedi tutti
              </Button>
            </a>
            <div className="separator-mobile"></div>
            <Text fontWeight="900" fontSize="2" variant="body">
              Consulenze legali con esperti
            </Text>
            {consultancyProductsMenuItem.map(templateMenuItem(onMenuIconClick))}
            <a href="/prodotti/">
              <Button
                variant="secondary"
                icon={<IoArrowForward />}
                iconOnRight
                fontSize="1"
                className="btn"
              >
                Vedi tutti
              </Button>
            </a>
          </FlexView>
        </ExpandableMenuMobileSection>

        {menuItems.map(templateMenuItem(onMenuIconClick))}

        <AccountAreaHeaderNavigation
          templateMenuItem={templateMenuItem(onMenuIconClick)}
          isExpanded={false}
        />

        <LoginLink onClick={onMenuIconClick} />
      </FlexView>
    );

    const popoverProps = {
      position: 'bottom',
      content,
      dismissOnScroll: false,
      event: 'click',
      isOpen: menuMobileOpen,
      onToggle: onMenuMobileClick,
    };

    return (
      <MenuMobileStyled className="menu-mobile" hAlignContent="right">
        <Popover popover={popoverProps as any}>
          <FlexView vAlignContent="center">
            <IoMenuOutline fontSize="30" />
          </FlexView>
        </Popover>
      </MenuMobileStyled>
    );
  };

  function getActiveSettingsRouteLabel(options, pathname) {
    return options?.find((o) => o.value === pathname)?.label || '';
  }

  const _isInterview = router.pathname === QUESTION;
  const isSubscribe =
    router.pathname === SUBSCRIBE ||
    router.pathname === SUBSCRIBE_WITH_DOCUMENT;
  const isUserArea =
    user?.role === 'Customer' &&
    (router.pathname === CUSTOMER_OVERVIEW ||
      router.pathname === CUSTOMER_NEW_ACTIVITIES ||
      router.pathname === CUSTOMER_ARCHIVE ||
      router.pathname === CUSTOMER_CONSULTANCIES ||
      router.pathname === CUSTOMER_CONSULTANCY_ROUTE ||
      router.pathname === CUSTOMER_ACCOUNTANTS ||
      router.pathname === CUSTOMER_PAYMENTS_AND_INVOICES ||
      router.pathname === CUSTOMER_ACCOUNT_DATA ||
      router.pathname === CUSTOMER_REFERRAL ||
      router.pathname === '/dati-aggiuntivi');

  const { payContent } = router.query;
  const { isReady: isRouterReady } = router;
  const hasPayContent = !!payContent;
  const hideHeaderNavigation = AppConstants.hideHeaderNavigation;
  const _documentKindTitle = optionalDocumentKind?.title || '';
  const showOnlyAuthLink = !!AppConstants.hideHeaderNavigation;

  function getTitleByPurchaseFlowOrGetDocumentKindTitle() {
    if (documentByHash?.purchaseFlow !== undefined) {
      switch (documentByHash.purchaseFlow) {
        case 'SRLCreation':
        case 'StartupCreation':
          return 'Apertura Società: crea lo statuto SRL';
        case 'SRLSCreation':
          return 'Apertura Società: crea lo statuto SRLS';
        case 'AssociationCreation':
          return 'Apertura Associazione: crea lo statuto';
        default:
          return '';
      }
    } else return _documentKindTitle;
  }

  function onBookAPhoneClick() {
    if (
      payContent === 'srl' ||
      payContent === 'srls' ||
      payContent === 'startup'
    ) {
      modalContext.setLivestormQuizKind('Company');
      modalContext.setIsLivestormModalOpen(true);
    } else if (payContent === 'associazione') {
      modalContext.setLivestormQuizKind('Association');
      modalContext.setIsLivestormModalOpen(true);
    } else if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: payContent
          ? 'https://calendly.com/lexdo-it/appuntamento-telefonico-nuove-attivita?utm_source=lexdoit&utm_medium=pay'
          : 'https://calendly.com/lexdo-it/appuntamento-telefonico-lexdo-it?utm_source=lexdoit&utm_medium=pay',
        prefill: {
          name:
            user.name || user.lastName
              ? `${user.name} ${user.lastName}`
              : user.companyName
              ? user.companyName
              : undefined,
          email: user.email,
          customAnswers: {
            a1: user.phoneNumber ? `+39${user.phoneNumber}` : '',
          },
        },
      });
    } else {
      showCustomerSupportChat();
    }
  }

  function onSaveClick() {
    modalContext.setShowRegistrationModal(true);
  }

  if (
    router.pathname === CUSTOMER_CONSULTANCY_ROUTE &&
    user?.role !== 'Customer'
  ) {
    return null;
  }

  if (isUserArea) {
    const settingsPageActive =
      router.pathname === CUSTOMER_PAYMENTS_AND_INVOICES ||
      router.pathname === CUSTOMER_ACCOUNT_DATA ||
      router.pathname === CUSTOMER_REFERRAL;

    const settingsOptionsCustomer = [
      {
        label: 'Dati utente e pagamenti',
        value: CUSTOMER_PAYMENTS_AND_INVOICES,
        icon: <IoPricetagOutline size={20} />,
        onClick: () => {
          router.push(CUSTOMER_PAYMENTS_AND_INVOICES);
          setMenuUserAreaDesktopOpen(false);
          setMenuUserAreaMobileOpen(false);
        },
      },
      {
        label: 'Piano attivo e upgrade',
        icon: <IoCardOutline size={20} />,
        value: CUSTOMER_ACCOUNT_DATA,
        onClick: () => {
          router.push(CUSTOMER_ACCOUNT_DATA);
          setMenuUserAreaDesktopOpen(false);
          setMenuUserAreaMobileOpen(false);
        },
      },
      {
        label: 'Guadagna con noi',
        icon: <IoHeartOutline size={20} />,
        value: CUSTOMER_REFERRAL,
        onClick: () => {
          router.push(CUSTOMER_REFERRAL);
          setMenuUserAreaDesktopOpen(false);
          setMenuUserAreaMobileOpen(false);
        },
      },
      {
        label: 'Esci',
        icon: <IoArrowForwardCircleOutline size={20} />,
        value: '',
        onClick: () => {
          setMenuUserAreaDesktopOpen(false);
          setMenuUserAreaMobileOpen(false);
          doLogout.mutateAsync();
        },
      },
    ];

    const optionsCustomer = [
      {
        color: variables.azure,
        label: 'Panoramica',
        value: CUSTOMER_OVERVIEW,
        icon: (
          <IconPanoramica isWhite={router.pathname === CUSTOMER_OVERVIEW} />
        ),
      },
      {
        color: variables.algae,
        label: 'Nuove Attività',
        value: CUSTOMER_NEW_ACTIVITIES,
        icon: (
          <IconNuoveAttività
            isWhite={router.pathname === CUSTOMER_NEW_ACTIVITIES}
          />
        ),
      },
      {
        color: variables.deepOrange,
        label: 'Contratti',
        value: CUSTOMER_ARCHIVE,
        icon: <IconContratti isWhite={router.pathname === CUSTOMER_ARCHIVE} />,
      },
      {
        color: variables.mustardYellow,
        label: 'Consulenze',
        value: CUSTOMER_CONSULTANCIES,
        icon: (
          <IconConsulenze
            isWhite={router.pathname === CUSTOMER_CONSULTANCIES}
          />
        ),
      },
      {
        color: variables.palatinateBlue,
        label: 'Contabilità',
        value: CUSTOMER_ACCOUNTANTS,
        icon: (
          <IconContabilità isWhite={router.pathname === CUSTOMER_ACCOUNTANTS} />
        ),
      },
    ];

    return (
      <HeaderStyled
        $showHeader={showHeader}
        $headerFader={headerFader}
        {...headerProps}
        hAlignContent="center"
        vAlignContent="center"
        style={{
          justifyContent: 'space-between',
          padding: 20,
        }}
      >
        {logoProps.variant && (
          <div
            className="item"
            style={{
              display: 'inline-block',
              width: 'auto',
              cursor: 'pointer',
            }}
            onClick={() => router.push(CUSTOMER_OVERVIEW)}
          >
            <Logo {...logoProps} />
          </div>
        )}

        {router.pathname !== '/dati-aggiuntivi' && (
          <>
            <UserAreaMenuDesktop
              vAlignContent="center"
              onClick={menuUserSettingsToggle}
            >
              <FlexView column hAlignContent="right">
                <Text
                  fontSize={1}
                  margin="0 9px 0 0"
                  lineHeight={1}
                  variant="body"
                >
                  {user?.name || user?.email || ''}
                </Text>
                {settingsPageActive && (
                  <Text
                    className="active-settings-page-label"
                    fontSize={1}
                    margin="0 9px 0 0"
                    lineHeight={1}
                    fontWeight={700}
                    variant="body"
                  >
                    Preferenze -{' '}
                    {getActiveSettingsRouteLabel(
                      settingsOptionsCustomer,
                      router.pathname
                    )}
                  </Text>
                )}
              </FlexView>
              <FaUserCircle
                size="24"
                color={
                  settingsPageActive
                    ? variables.azure
                    : variables.iconsLightGray
                }
              />
              <FlexView
                className="settings-arrow"
                hAlignContent="center"
                vAlignContent="center"
              >
                {!menuUserAreaDesktopOpen ? (
                  <IoChevronDown size="24" />
                ) : (
                  <IoChevronUp size="24" />
                )}
              </FlexView>
              {menuUserAreaDesktopOpen && (
                <div
                  className="settings-drop-menu"
                  ref={menuUserAreaDesktopRef}
                >
                  {settingsOptionsCustomer.map(
                    ({ label, value, onClick, icon }, index) => (
                      <FlexView
                        className={cx('drop-menu-item', {
                          last: index === settingsOptionsCustomer.length - 1,
                          activeRoute: router.pathname === value,
                        })}
                        key={`setting-${label}`}
                        onClick={onClick}
                        vAlignContent="center"
                        hAlignContent="right"
                      >
                        {label}
                        {icon}
                      </FlexView>
                    )
                  )}
                </div>
              )}
            </UserAreaMenuDesktop>

            <UserAreaMenuMobile>
              <IoMenuOutline fontSize="30" onClick={menuUserAreaMobileToggle} />

              {menuUserAreaMobileOpen && (
                <FlexView className="mobile-menu-wrapper" column>
                  <FlexView className="menu-items-wrapper-top" column>
                    {optionsCustomer.map(({ color, label, value, icon }) => (
                      <FlexView
                        className={cx('menu-item-top', {
                          activeRoute: router.pathname === value,
                        })}
                        key={`menu-item-top-${label}`}
                        hAlignContent="left"
                        vAlignContent="center"
                        onClick={() => {
                          router.push(value);
                          menuUserAreaMobileToggle();
                        }}
                      >
                        <FlexView
                          className="menu-item-top-icon-wrapper"
                          hAlignContent="center"
                          vAlignContent="center"
                          style={{
                            backgroundColor:
                              router.pathname === value
                                ? color
                                : variables.iconsGrayBg,
                          }}
                        >
                          {icon}
                        </FlexView>
                        <Text fontSize={1} marginLeft={10} variant="body">
                          {label}
                        </Text>
                      </FlexView>
                    ))}
                  </FlexView>
                  <FlexView className="menu-items-wrapper-bottom" column>
                    {settingsOptionsCustomer.map(
                      ({ label, value, onClick, icon }, i) => (
                        <FlexView
                          className={cx('menu-item-bottom', {
                            activeRoute: router.pathname === value,
                          })}
                          key={`menu-item-bottom-${label}`}
                          hAlignContent="left"
                          vAlignContent="center"
                          onClick={onClick}
                        >
                          <FlexView
                            className="menu-item-bottom-icon-wrapper"
                            hAlignContent="center"
                            vAlignContent="center"
                          >
                            {icon}
                          </FlexView>
                          <Text fontSize={0} variant="body">
                            {label}
                          </Text>
                        </FlexView>
                      )
                    )}
                  </FlexView>
                </FlexView>
              )}
            </UserAreaMenuMobile>
          </>
        )}
      </HeaderStyled>
    );
  }

  return (
    <HeaderStyled
      $showHeader={showHeader}
      $headerFader={headerFader}
      {...headerProps}
      hAlignContent="center"
      vAlignContent="center"
    >
      <CalendlyHelper />
      <WrapperStyled className="wrapper" vAlignContent="center" grow>
        <FlexView className="left-side" shrink={false} vAlignContent="center">
          {logoProps.variant && (
            <a
              href={
                user
                  ? user.role === 'Lawyer'
                    ? `/app/${LAWYER_ROOT}`
                    : `/app${CUSTOMER_OVERVIEW}`
                  : '/'
              }
              className="item"
              style={{ display: 'inline-block', width: 'auto' }}
            >
              <Logo {...logoProps} />
            </a>
          )}
        </FlexView>

        {_isInterview && (
          <FlexView className="interview-wrapper" grow vAlignContent="center">
            <div className="interview-title">
              <span>
                <b>{getTitleByPurchaseFlowOrGetDocumentKindTitle()}</b>
              </span>
            </div>
            {!hideHeaderNavigation && (
              <FlexView
                className="right-side"
                hAlignContent="right"
                vAlignContent="center"
              >
                <DocumentSaveForLater onSaveClick={onSaveClick} />
              </FlexView>
            )}
          </FlexView>
        )}

        {isSubscribe &&
        hasPayContent &&
        !AppConstants.experimentalCallUsButton &&
        payContent !== 'piva' ? (
          <FlexView className="interview-wrapper" grow vAlignContent="center">
            <CalendlyHelper />
            <LivestormHelper />
            <FlexView
              className="right-side"
              hAlignContent="right"
              vAlignContent="center"
            >
              <span className="book-phone-call-label">Hai domande?</span>
              {payContent !== 'piva' ? (
                <Button
                  className="book-phone-cta"
                  icon={<BsFillChatFill />}
                  size="medium"
                  onClick={() => {
                    showCustomerSupportChat();
                  }}
                >
                  Parla gratis con un esperto
                </Button>
              ) : (
                <Button
                  className="book-phone-cta"
                  icon={<FaPhoneAlt />}
                  size="medium"
                  onClick={onBookAPhoneClick}
                >
                  Parla gratis con un esperto
                </Button>
              )}
            </FlexView>
          </FlexView>
        ) : null}

        {!hasPayContent && isSubscribe && isRouterReady ? (
          <FlexView className="interview-wrapper" grow vAlignContent="center">
            <CalendlyHelper />
            <FlexView
              className="right-side"
              hAlignContent="right"
              vAlignContent="center"
            >
              <span className="book-phone-call-label">Hai domande?</span>
              <Button
                className="book-phone-cta"
                icon={<FaPhoneAlt />}
                size="medium"
                onClick={onBookAPhoneClick}
              >
                Parla gratis con un esperto
              </Button>
            </FlexView>
          </FlexView>
        ) : null}

        {isSubscribe &&
          hasPayContent &&
          AppConstants.experimentalCallUsButton && (
            <FlexView className="interview-wrapper" grow vAlignContent="center">
              <FlexView
                className="right-side"
                hAlignContent="right"
                vAlignContent="center"
              >
                <span className="book-phone-call-label">Hai domande?</span>
                <a href="tel:0280896993">
                  <Button
                    className="book-phone-cta"
                    icon={<FaPhoneAlt />}
                    size="medium"
                  >
                    Parla gratis con esperto (+39) 02 80896993
                  </Button>
                </a>
              </FlexView>
            </FlexView>
          )}

        {showMenu && (
          <FlexView
            className="right-side"
            grow
            hAlignContent="right"
            vAlignContent="center"
          >
            {templateMenu({ menuItems, isLanding, hideSignupLink })}
            {templateMenuMobile({
              menuItems,
              onMenuIconClick,
            })}
          </FlexView>
        )}

        {showOnlyAuthLink && (
          <FlexView
            className="right-side"
            grow
            hAlignContent="right"
            vAlignContent="center"
          >
            <FlexView
              className="desktop-menu"
              grow
              hAlignContent="right"
              vAlignContent="center"
            >
              <FlexView className="header-menu" shrink={false}>
                <LoginLink />
              </FlexView>
            </FlexView>
          </FlexView>
        )}
      </WrapperStyled>
    </HeaderStyled>
  );
}
