import variables from 'styles/themeVariables';

interface IProps {
  isWhite?: boolean;
  isCustom?: boolean;
  color?: string;
  color2?: string;
}

export default function IconContratti(props: IProps) {
  const { isWhite, isCustom, color, color2 } = props;

  return (
    <svg
      width="17px"
      height="19px"
      viewBox="0 0 17 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>contratti - icon - default</title>
      <g
        id="visual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="menu---default"
          transform="translate(-38.000000, -273.000000)"
          fillRule="nonzero"
        >
          <g id="contratti" transform="translate(9.000000, 262.500000)">
            <g
              id="contratti---icon---default"
              transform="translate(29.205247, 10.648859)"
            >
              <path
                d="M9.63061612,8.92329148 C9.69258664,8.92334457 9.74853949,8.88621192 9.77256506,8.8290882 C9.79659063,8.77196447 9.78399817,8.70600247 9.74061876,8.66174668 L6.72516166,5.59551912 C6.68133235,5.55101341 6.61494407,5.53742549 6.55715346,5.56113229 C6.49936285,5.5848391 6.46164206,5.64113453 6.46169374,5.70359864 L6.46169374,8.46174187 C6.46169374,8.71664865 6.66833657,8.92329148 6.92324335,8.92329148 L9.63061612,8.92329148 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
              ></path>
              <path
                d="M5.73090699,9.65407822 C5.41181616,9.33927243 5.2317919,8.90998382 5.23089497,8.46174187 L5.23089497,4.92319527 L2.46159763,4.92319527 C1.10376061,4.9272085 0.00401323362,6.02695588 0,7.3847929 L0,16.0003846 C0,17.3598875 1.1020948,18.4619823 2.46159763,18.4619823 L8.00019231,18.4619823 C9.35969515,18.4619823 10.4617899,17.3598875 10.4617899,16.0003846 L10.4617899,10.1540902 L6.92324335,10.1540902 C6.47494944,10.1534504 6.0455658,9.97338634 5.73090699,9.65407822 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color
                    : variables.iconsDarkGray
                }
              ></path>
              <path
                d="M12.461838,4.00009621 L15.1692108,4.00009621 C15.2311813,4.0001493 15.2871342,3.96301665 15.3111597,3.90589293 C15.3351853,3.8487692 15.3225928,3.7828072 15.2792134,3.73855141 L12.2637563,0.672323854 C12.219927,0.627818139 12.1535388,0.614230218 12.0957481,0.637937025 C12.0379575,0.661643831 12.0002367,0.717939266 12.0002884,0.780403375 L12.0002884,3.5385466 C12.0002884,3.79345338 12.2069312,4.00009621 12.461838,4.00009621 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
              <path
                d="M12.461838,5.23089497 C11.5301438,5.2237777 10.7766069,4.4702408 10.7694897,3.5385466 L10.7694897,0 L6.61554364,0 C5.34162479,0.0014836438 4.3092795,1.03382893 4.30779586,2.30774778 L4.30779586,3.69239645 L5.9278348,3.69239645 C6.34547475,3.69406634 6.74522969,3.86210737 7.03863074,4.15933075 L11.2371932,8.42866415 C11.5301016,8.72586334 11.6936553,9.12679797 11.6922041,9.54407558 L11.6922041,14.7695858 L13.7326378,14.7695858 C14.9830525,14.7695858 16,13.7341763 16,12.461838 L16,5.23089497 L12.461838,5.23089497 Z"
                id="Path"
                fill={
                  isWhite
                    ? variables.white
                    : isCustom
                    ? color2
                    : variables.iconsLightGray
                }
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
