import { useQuery } from 'react-query';
import { useAxios } from '../context/AxiosContext';
import { APIUserAccountPreview } from 'lexdoit-models';

interface APIAccountPreview {
  hasPendingConsultancies: boolean;
  hasPendingNewActivities: boolean;
  accountingSubscription: {
    assignationStatus: string;
    consultancyId: number;
    consultant: { _userId: number; displayName: string; calendlyLink: string };
    subscriptionExpiresOn: string;
    subscriptionStatus: string;
    subscriptionTier: string;
  };
  subscription: {
    consultanciesLimit: number;
    consultanciesLimitReached: boolean;
    consultanciesUsed: number;
    documentsLimit: number;
    documentsLimitReached: boolean;
    documentsUsed: number;
    subscriptionExpiresOn: string;
    subscriptionStatus: string;
    subscriptionTier: string;
  };
  lastActiveNewActivity: {
    quiz?: {
      answers: any;
      createdOn: string;
      kind: string;
    };
    document?: {
      answersSummary: any;
      complete: boolean;
      createdOn: string;
      documentKindUpdatedAfterDocument: boolean;
      documentName: string;
      editable: boolean;
      hasBrokenDocKind: boolean;
      paid: boolean;
      purchaseFlow: string;
      updatedOn: string;
      urlHash: string;
      _documentKindId: number;
      _id: number;
    };
    consultancy?: {
      consultancyKind: string;
      createdOn: string;
      email: string;
      phoneNumber: string;
      quotes: any;
      request: string;
      status: string;
      updatedOn: string;
      _id: number;
    };
  };
  _oldestActiveConsultancyId: number;
}

export default function useGetAccountPreview() {
  // Get our Axios instance from our previously created Hook
  const axios = useAxios();

  // Create a query with the key `projects`
  return useQuery<APIUserAccountPreview>('accountPreviewInfos', async () => {
    // Fetch data from our API using Axios. We'll talk about the typing below
    const { data } = await axios.get<APIUserAccountPreview>(
      '/users/getAccountPreview',
      {
        params: {},
      }
    );

    // Return the data from the Axios response
    return data;
  });
}
