import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

// libs
import { theme } from 'ds';
import { ThemeProvider } from 'styled-components';
import FlexView from 'react-flexview/lib';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Head from 'next/head';

// utils
import ReactGA from '../utils/ReactGA';
import ReactGA4 from '../utils/ReactGA4';
import AppConstants from 'utils/AppConstants';

// components
import HeaderFader from '../components/HeaderFader';
import Footer from 'components/Footer';
import ScriptWrapper from 'components/ScriptWrapper';
import CustomerSupportChat from 'components/CustomerClientComponent/CustomerSupportChat';

// style const
import themeVariables from 'styles/themeVariables';
import GlobalStyle from 'styles/globalStyle';
import 'react-quill/dist/quill.snow.css';

// const
import { CUSTOMER_CONSULTANCY_ROUTE } from 'constants/routes';

// context
import { ModalContextProvider } from 'context/ModalContext';
import AxiosProvider from '../context/AxiosContext';

// queries
import useUser from 'queries/useUser';
import Script from 'next/script';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000,
    },
  },
});

function Layout(props) {
  const { children } = props;
  const { data: user } = useUser();

  const router = useRouter();
  const isLawyerConsultancy =
    router.pathname === CUSTOMER_CONSULTANCY_ROUTE &&
    (user?.role === 'Lawyer' || user?.role === 'Accountant');

  const withTopMargin = !isLawyerConsultancy;

  return (
    <FlexView
      className={`layout ${withTopMargin ? 'with-top-padding' : ''}`}
      column
      shrink={false}
      grow
    >
      {children}
    </FlexView>
  );
}

// Init client sfuff ASAP
if (typeof window !== 'undefined') {
  ReactGA.initialize(false);
  ReactGA4.initialize();
}

function MyApp({ Component, pageProps }: AppProps) {
  const { cookieYesEnvId } = AppConstants;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=0"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AxiosProvider>
          <ThemeProvider theme={{ ...theme.light, ...themeVariables }}>
            <ModalContextProvider>
              <GlobalStyle />
              <ReactQueryDevtools />
              <Layout>
                <HeaderFader showMenu />
                <FlexView className="next" shrink={false} grow>
                  <Component {...pageProps} />
                </FlexView>
                <Footer bigFooter />
              </Layout>

              <CustomerSupportChat />

              <Script
                async
                id="cookieyes"
                type="text/javascript"
                src={`https://cdn-cookieyes.com/client_data/${cookieYesEnvId}/script.js`}
              />

              <ScriptWrapper />
            </ModalContextProvider>
          </ThemeProvider>
        </AxiosProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
